import { type UseMutationResult } from '@tanstack/react-query'
import { useEffect, useState } from 'react'

import Modal from '@components/modal/modal'
import { NotificationType } from '@components/notification/notification.interfaces'
import { type ApiReponseError } from '@interfaces/api'
import { type SlideshowItem } from '@interfaces/api/slideshow-item'
import { type TranslationData } from '@interfaces/api/translation-data'
import SlideshowItemForm, {
  type SlideshowItemFormValues
} from '@pages/settings/settings-slideshow/slideshow-item-form/slideshow-item-form'
import useCreateSlideshowItem from '@services/api/use-create-slideshow-item'
import useGetSlideshowItem from '@services/api/use-get-slideshow-item'
import usePatchSlideshowItem from '@services/api/use-patch-slideshow-item'
import { useUploadImage } from '@services/api/use-upload-image'
import { captureException } from '@services/exceptions/capture-exception'
import { LanguageCodes } from '@services/languages'
import { useNotificationStore } from '@services/stores/notification/notification'
import { handleImageViolations } from '@services/tools/violations'

interface SlideshowItemModalProps {
  onSuccess: (item: SlideshowItem) => void
  open: boolean
  setOpen: (open: boolean) => void
  uid?: string
}

const SlideshowItemModal = ({ onSuccess, open, setOpen, uid }: SlideshowItemModalProps) => {
  const { data: slideshow, refetch } = useGetSlideshowItem(uid ?? '')
  const { mutateAsync: patchSlideshowItem } = usePatchSlideshowItem()
  const { mutateAsync: postSlideshowItem } = useCreateSlideshowItem()
  const { mutateAsync: uploadImage } = useUploadImage()
  const [errorMsg, setErrorMsg] = useState('')

  const languages = Object.values(LanguageCodes)

  const createTranslatedData = () => {
    const translatedData: TranslationData = {}
    languages.forEach(lang => {
      translatedData[lang] = null
    })

    return translatedData
  }

  const baseInitialValues = {
    ctaLink: '',
    ctaTitle: '',
    description: '',
    image: null,
    priority: null,
    title: '',
    translatedData: createTranslatedData()
  }

  const [initialValues, setInitialValues] = useState<SlideshowItemFormValues>(baseInitialValues)

  const { displayNotification } = useNotificationStore()

  useEffect(() => {
    if (uid && slideshow) {
      const translatedData = createTranslatedData()
      languages.forEach(lang => {
        translatedData[lang] = slideshow?.translatedData?.[lang] ?? null
      })

      setInitialValues({
        ctaLink: slideshow?.ctaLink,
        ctaTitle: slideshow?.ctaTitle,
        description: slideshow?.description,
        image: slideshow?.image ?? null,
        priority: slideshow?.priority ?? null,
        title: slideshow?.title,
        translatedData
      })
    } else {
      setInitialValues(baseInitialValues)
    }
  }, [uid, slideshow])

  const handleSubmit = async (
    values,
    { setSubmitting }
  ) => {
    setSubmitting(true)

    try {
      if (values.image) {
        if ('@id' in values.image) {
          values.image = values.image['@id']
        } else {
          const formData = new FormData()
          formData.append('file', values.image)
          try {
            values.image = await uploadImage(formData)
          } catch (e) {
            const err = e as UseMutationResult<ApiReponseError>
            setErrorMsg(handleImageViolations(err?.data?.violations ?? []))
          }
        }
      }

      let result
      if (uid) {
        result = await patchSlideshowItem({
          data: values,
          uid
        })
        displayNotification('Content updated', 'Your showcase content has been updated.', NotificationType.success)
      } else {
        result = await postSlideshowItem({
          ...values
        })
        displayNotification('Content created', 'Your showcase content has been created.', NotificationType.success)
      }

      onSuccess(result)

      setSubmitting(false)

      refetch().catch(captureException)
      setOpen(false)
    } catch (err) {
      captureException(err as Error)
      setSubmitting(false)
    }
  }

  return (
    <Modal center open={open} setOpen={setOpen} size={'large'}>
      <SlideshowItemForm initialValues={initialValues} onSubmit={handleSubmit} />

      <div className='text-xs font-medium text-red-500'>{errorMsg}</div>
    </Modal>
  )
}

export default SlideshowItemModal
