import { ErrorMessage, Field } from 'formik'

import { type FormTextFieldProps } from '@components/form-text-field/form-text-field.interfaces'

const FormTextField = ({ label, name, placeholder, required = false, type = 'text' }: FormTextFieldProps) => {
  return (
    <div className='flex flex-col'>
      <label className='text-sm font-bold' htmlFor={name}>
        {label}
      </label>

      <Field
        className='mt-2 block w-full rounded-lg border px-3 py-2.5 text-sm'
        name={name}
        placeholder={placeholder}
        required={required}
        type={type}
      />

      <ErrorMessage className='mt-2 text-xs font-bold text-primary' component='div' name={name} />
    </div>
  )
}

export default FormTextField
