import { create } from 'zustand'

interface CartItem {
  experience: string
  quantity: number
  unitPrice: number
}

interface CartStore {
  addToCart: (item: CartItem) => void
  clearCart: () => void
  getCartUid: () => string | null
  getPaymentUid: () => string | null
  getTotal: () => number
  items: CartItem[]
  paymentUid: string | null
  setCartUid: (uid: string) => void
  setPaymentUid: (uid: string) => void
  uid: string | null
}

const projectEnv = process.env.NODE_ENV || 'development'
const projectName = 'citycrushPro_1'
const storageKeyPrefix = `${projectEnv}-${projectName}-`

const cartItemsKey = `${storageKeyPrefix}_cartItems`
const cartUidKey = `${storageKeyPrefix}_cartUid`
const paymentUidKey = `${storageKeyPrefix}_paymentUid`

export const useCartStore = create<CartStore>((set) => ({
  addToCart: (item) => {
    set((state) => {
      const existingItemIndex = state.items.findIndex((i) => i.experience === item.experience)
      let newItems
      if (existingItemIndex !== -1) {
        // Si la quantité est réduite à zéro, supprimez l'élément
        if (item.quantity === 0) {
          newItems = state.items.filter((i, index) => index !== existingItemIndex)
        } else {
          // Sinon, mettez à jour la quantité
          newItems = [...state.items]
          newItems[existingItemIndex] = {
            ...newItems[existingItemIndex],
            quantity: item.quantity
          }
        }
      } else {
        // Si l'élément n'existe pas et que la quantité est supérieure à zéro, ajoutez-le
        if (item.quantity > 0) {
          newItems = [...state.items, item]
        } else {
          // Sinon, ne faites aucun changement
          newItems = state.items
        }
      }
      localStorage.setItem(cartItemsKey, JSON.stringify(newItems))

      return { items: newItems, uid: state.uid }
    })
  },
  clearCart: () => {
    set(() => {
      localStorage.removeItem(cartItemsKey)
      localStorage.removeItem(cartUidKey)
      localStorage.removeItem(paymentUidKey)

      return { items: [], uid: null }
    })
  },
  getCartUid: () => {
    return useCartStore.getState().uid
  },
  getPaymentUid: () => {
    return useCartStore.getState().paymentUid
  },
  getTotal: () => {
    return useCartStore
      .getState()
      .items.reduce((total, item) => total + item.quantity * item.unitPrice, 0)
  },
  items: JSON.parse(localStorage.getItem(cartItemsKey) ?? '[]'),
  paymentUid: localStorage.getItem(paymentUidKey) ?? null,
  setCartUid: (uid) => {
    localStorage.setItem(cartUidKey, uid)
    set({ uid })
  },
  setPaymentUid: (paymentUid) => {
    localStorage.setItem(paymentUidKey, paymentUid)
    set({ paymentUid })
  },
  uid: localStorage.getItem(cartUidKey) ?? null
}))
