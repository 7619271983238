import { useQuery, type UseQueryResult } from '@tanstack/react-query'

import { type ApiPlatformEntity, type ApiPlatformListResponse } from '@interfaces/api/api'
import useAxios from '@services/api/axios'
import { formatApiListResponse } from '@services/tools/format-list-response'

interface ResourceListParameters {
  parameters?: Record<string, boolean | number | string> | URLSearchParams
}

const useGetExperience = <T extends ApiPlatformEntity>({ parameters }: ResourceListParameters) => {
  const { get } = useAxios()

  const queryKey = ['partner-realm/experiences', parameters]
  const queryFn = async () => {
    return await get('api/partner-realm/experiences', parameters)
  }
  const staleTime = 5 * 60 * 1000

  const { data, isFetching, refetch, ...useQueryVars }: UseQueryResult<ApiPlatformListResponse<T>> =
    useQuery({
      queryFn,
      queryKey,
      staleTime
    })

  return {
    data: data ? formatApiListResponse(data) : undefined,
    isFetching,
    refetch,
    ...useQueryVars
  }
}

export default useGetExperience
