import { type AxiosResponse } from 'axios'
import { ErrorMessage, Field, Form, Formik, type FormikConfig } from 'formik'
import { useState } from 'react'
import * as Yup from 'yup'

import ButtonLoader from '@components/button-loader/button-loader'
import { ApiError } from '@interfaces/api/error'
import { useForgotPasswordMutation } from '@services/api/auth/use-forgot-password-mutation'
import { captureException } from '@services/exceptions/capture-exception'

const ForgotPassword = () => {
  const [requestSuccess, setRequestSuccess] = useState(false)
  const FormSchema = Yup.object({
    email: Yup.string().email('Bad email format').required('Required')
  })

  const { mutateAsync: forgotPassword } = useForgotPasswordMutation()
  const [errorMsg, setErrorMsg] = useState('')

  const onSubmit: FormikConfig<{ email: string }>['onSubmit'] = async (
    values,
    { setSubmitting }
  ) => {
    setSubmitting(true)
    setErrorMsg('')

    try {
      await forgotPassword(values)
      setSubmitting(false)
      setRequestSuccess(true)
    } catch (err) {
      captureException(err as Error)
      setSubmitting(false)
      const error = err as AxiosResponse

      if (error.data.message === ApiError.PARTNER_NOT_VERIFIED) {
        setErrorMsg(
          'Account pending email validation. Please check your emails and verify your account.'
        )
      } else if (error.data.message === ApiError.PARTNER_NOT_VALIDATED) {
        setErrorMsg('Your account is currently being verified by our team.')
      } else if (error.data.message === ApiError.INVALID_CREDENTIAL) {
        setErrorMsg('Wrong email or password')
      }
    }
  }

  return (
    <div className='flex min-h-full flex-1'>
      <div className='flex flex-1 flex-col justify-center px-4 py-12 sm:px-6 lg:px-20 xl:px-24'>
        <div className='mx-auto w-full max-w-sm lg:w-96'>
          <div className='flex flex-col items-center'>
            <a href='/'>
              <img alt='logo' className='h-20' src='/assets/citycrush.svg' />
            </a>

            <h2 className='mt-8 text-xl font-bold leading-9 tracking-tight text-gray-900'>
              Let's reset your password
            </h2>
          </div>

          {!requestSuccess
            ? (
              <div className='mt-6'>
                <Formik
                  initialValues={{
                    email: ''
                  }}
                  onSubmit={onSubmit}
                  validationSchema={FormSchema}
                >
                  {({ isSubmitting }) => {
                    return (
                      <Form className='space-y-6'>
                        <div className='flex flex-col'>
                          <label className='text-sm font-bold' htmlFor='email'>
                            Email
                          </label>

                          <Field
                            autoComplete='email'
                            className='mt-2 block w-full appearance-none rounded-lg border border-gray-200 px-3 py-2.5 text-sm placeholder:text-gray-400 focus:border-primary focus:outline-none'
                            name='email'
                            placeholder='exemple@gmail.com'
                            type='email'
                          />

                          <ErrorMessage
                            className='mt-2 text-xs font-bold text-primary'
                            component='div'
                            name='email'
                          />
                        </div>

                        <div className='text-xs font-medium text-red-500'>{errorMsg}</div>

                        <button
                          className='flex w-full justify-center rounded-full border-0 bg-primary py-2 text-white shadow-sm sm:text-sm sm:leading-6'
                          disabled={isSubmitting}
                          type='submit'
                        >
                          {isSubmitting ? <ButtonLoader /> : <>SUBMIT</>}
                        </button>
                      </Form>
                    )
                  }}
                </Formik>

                <div className='mt-10 space-x-1 text-center text-sm'>
                  <span>Don't have an account ?</span>

                  <a className='text-primary underline' href='/register'>
                    Sign up
                  </a>
                </div>
              </div>
            )
            : (
              <div className='mt-6 flex flex-col items-center justify-center space-y-6'>
                <p className='text-center'>We've just sent you an email with instructions to reset your password.</p>

                <a
                  className='flex w-full justify-center rounded-full border-0 bg-primary py-2 text-white shadow-sm sm:text-sm sm:leading-6'
                  href='/login'
                >
                  BACK TO LOG IN
                </a>
              </div>
            )}
        </div>
      </div>

      <div className='relative hidden w-0 flex-1 lg:block'>
        <img
          alt=''
          className='absolute inset-0 size-full object-cover'
          src='/assets/bg-login.jpg'
        />
      </div>
    </div>
  )
}

export default ForgotPassword
