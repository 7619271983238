import { useMutation, type UseMutationResult } from '@tanstack/react-query'

import {
  type ApiRequestForgotPassword,
  type ApiResponseForgotPassword
} from '@interfaces/api/forgot-password/forgot-password'
import useAxios from '@services/api/axios'

export const useForgotPasswordMutation = (): UseMutationResult<
ApiResponseForgotPassword,
unknown,
ApiRequestForgotPassword
> => {
  const { post } = useAxios()

  return useMutation<ApiResponseForgotPassword, unknown, ApiRequestForgotPassword>({
    mutationFn: async (data) =>
      await post<ApiRequestForgotPassword, ApiResponseForgotPassword>('/api/partner-realm/forget-password', data)
  })
}
