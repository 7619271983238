import { CodeBulksList } from '@pages/codes/code-bulk'
import { InventoryList } from '@pages/codes/inventory'
import { useFeatures } from '@services/features'

const CodesList = () => {
  const { hasBookingFeature, hasCodeBulkFeature, hasPartnerCodeBulkFeature, isReady } = useFeatures()

  if (!isReady) {
    return (
      <div>Loading</div>
    )
  }

  if (hasBookingFeature) {
    return (
      <InventoryList />
    )
  }

  if (hasCodeBulkFeature || hasPartnerCodeBulkFeature) {
    return (
      <CodeBulksList />
    )
  }

  return (
    <div>No access</div>
  )
}

export default CodesList
