import { Disclosure, Menu, Transition } from '@headlessui/react'
import { UserIcon } from '@heroicons/react/24/outline'
import { PlusIcon } from '@heroicons/react/24/solid'
import classNames from 'classnames'
import { Fragment } from 'react'
import { NavLink } from 'react-router-dom'

import { useMe } from '@services/api/auth/use-me'
import { captureException } from '@services/exceptions/capture-exception'
import { useFeatures } from '@services/features'
import { useLogout } from '@services/hooks/auth-token'
import { useCartStore } from '@services/stores/cart/cart'

const MainMenu = () => {
  const { logout } = useLogout()
  const { data: me } = useMe()
  const { clearCart } = useCartStore()

  const logoutClick = () => {
    logout().catch(captureException)
    clearCart()
  }

  const { hasBookingFeature, hasCodeBulkFeature, hasPartnerCodeBulkFeature } = useFeatures()

  const defaultLinkStyle = 'inline-flex items-center rounded-full border px-5 py-2 text-sm font-semibold text-black'

  return (
    <Disclosure as='nav' className='bg-white'>
      {({ open }) => (
        <>
          <div className='mx-auto mt-4 max-w-7xl px-2 sm:px-6 lg:px-8'>
            <div className='relative flex h-16 justify-between'>
              <div className='flex flex-1 items-center justify-between rounded-full border border-gray-200 px-4 py-3'>
                <div className='flex items-center'>
                  <div className='flex shrink-0 items-center'>
                    <a href='/'>
                      <img
                        alt='City Crush'
                        className='h-5 w-auto'
                        src='/assets/citycrush-small.svg'
                      />
                    </a>
                  </div>

                  <div className='hidden sm:ml-6 sm:flex sm:space-x-8'>
                    {hasBookingFeature && (
                      <NavLink
                        className={({ isActive }) =>
                          isActive
                            ? `${defaultLinkStyle} border-black bg-black text-white`
                            : `${defaultLinkStyle} border-gray-200 bg-white text-black`
                        }
                        to='/'
                      >
                        Booking
                      </NavLink>
                    )}

                    {(hasCodeBulkFeature || hasBookingFeature || hasPartnerCodeBulkFeature) && (
                      <NavLink
                        className={({ isActive }) =>
                          isActive
                            ? `${defaultLinkStyle} border-black bg-black text-white`
                            : `${defaultLinkStyle} border-gray-200 bg-white text-black`
                        }
                        to='/codes'
                      >
                        Codes
                      </NavLink>
                    )}
                  </div>
                </div>

                <div className='flex items-center justify-self-end pr-2 sm:static sm:ml-6 sm:pr-0'>
                  <a
                    className={classNames(
                      'inline-flex items-center space-x-2 rounded-full border border-gray-200 py-1 pl-1 pr-5 text-sm font-semibold',
                      {
                        'bg-black text-white': me?.validated,
                        'cursor-not-allowed bg-gray-300 text-white': !me?.validated
                      }
                    )}
                    href={me?.validated ? '/purchase' : '/'}
                  >
                    <span className='rounded-full bg-primary p-1'>
                      <PlusIcon className='size-7' />
                    </span>

                    <span>Buy new codes</span>
                  </a>

                  <NavLink
                    className={({ isActive }) =>
                      isActive
                        ? 'ml-3 text-sm font-bold underline'
                        : 'ml-3 text-sm font-bold'
                    }
                    to='/settings'
                  >
                    Settings
                  </NavLink>

                  {/* Profile dropdown */}

                  <Menu as='div' className='relative ml-5'>
                    <div>
                      <Menu.Button
                        className='relative flex rounded-full bg-white text-sm focus:outline-none focus:ring-1 focus:ring-black focus:ring-offset-2'
                      >
                        <span className='absolute -inset-1.5' />

                        <span className='sr-only'>Open user menu</span>

                        {me?.logo
                          ? (
                            <img alt='Company logo' className='size-8 rounded-full' src={me.logo.url} />
                          )
                          : (
                            <div className='rounded-full border border-gray-300 p-2'>
                              <UserIcon className='size-6 rounded-full' />
                            </div>
                          )}
                      </Menu.Button>
                    </div>

                    <Transition
                      as={Fragment}
                      enter='transition ease-out duration-200'
                      enterFrom='transform opacity-0 scale-95'
                      enterTo='transform opacity-100 scale-100'
                      leave='transition ease-in duration-75'
                      leaveFrom='transform opacity-100 scale-100'
                      leaveTo='transform opacity-0 scale-95'
                    >
                      <Menu.Items
                        className='absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-sm ring-1 ring-black/5 focus:outline-none'
                      >
                        <Menu.Item>
                          {({ focus }) => (
                            <button
                              className={classNames(
                                focus ? 'bg-gray-100' : '',
                                'flex w-full px-4 py-2 text-sm text-gray-700'
                              )}
                              onClick={logoutClick}
                            >
                              Log out
                            </button>
                          )}
                        </Menu.Item>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </div>
              </div>
            </div>
          </div>

          <Disclosure.Panel className='sm:hidden'>
            <div className='space-y-1 pb-4 pt-2'>
              <Disclosure.Button
                as='a'
                className='block border-l-4 py-2 pl-3 pr-4 text-base font-medium text-gray-900'
                href='#'
              >
                My account
              </Disclosure.Button>
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  )
}

export default MainMenu
