import { Dialog, DialogPanel } from '@headlessui/react'
import { CheckIcon, XMarkIcon } from '@heroicons/react/24/outline'
import classNames from 'classnames'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router'

import ExperienceCard from '@components/experience-card/experience-card'
import Guard from '@components/guard/guard'
import Layout from '@components/layout/layout'
import Modal from '@components/modal/modal'
import { type Experience } from '@interfaces/api/experience'
import { type Order } from '@interfaces/api/order'
import useCancelPayment from '@services/api/use-cancel-payment'
import useCreateOrder, { OrderType } from '@services/api/use-create-order'
import useGetExperience from '@services/api/use-get-experiences'
import useGetOrder from '@services/api/use-get-order'
import usePatchOrder from '@services/api/use-patch-order'
import { captureException } from '@services/exceptions/capture-exception'
import { useFeatures } from '@services/features'
import { useCartStore } from '@services/stores/cart/cart'

const Purchase = () => {
  const { data: experiences } = useGetExperience<Experience>({})
  const { addToCart, clearCart, getCartUid, getPaymentUid, getTotal, items, setCartUid } =
    useCartStore()

  const [timeoutId, setTimeoutId] = useState<unknown>(null)
  const [openModalMinimum, setOpenModalMinimum] = useState<boolean>(false)
  const [openDrawer, setOpenDrawer] = useState<boolean>(false)
  const [drawerExperience, setDrawerExperience] = useState<Experience | null>()
  const [initialQuantityInCart, setInitialQuantityInCart] = useState<number>(0)
  const [drawerQuantity, setDrawerQuantity] = useState<number>(0)
  const location = useLocation()

  const cartUid = getCartUid()

  const { hasPartnerCodeBulkFeature, isReady } = useFeatures()
  const { mutateAsync: createOrder } = useCreateOrder()
  const { mutateAsync: patchOrder } = usePatchOrder()
  const { mutateAsync: cancelPayment } = useCancelPayment()
  const { data: order, error, refetch } = useGetOrder(cartUid)
  const navigate = useNavigate()

  useEffect(() => {
    if (error) {
      clearCart()
    }
  }, [error])

  const handleDetailsClick = async (experience: Experience) => {
    setOpenDrawer(true)
    setDrawerExperience(experience)

    const quantityInCart =
      items.find((item) => item.experience === experience.uid)?.quantity

    setInitialQuantityInCart(quantityInCart)
    setDrawerQuantity(quantityInCart ?? 1)
  }

  const handleDrawerAddToCart = async () => {
    if (drawerExperience) {
      await handleAddToCart(drawerExperience, drawerQuantity)
      setOpenDrawer(false)
      setDrawerExperience(null)
      setDrawerQuantity(0)
    }
  }

  const handleDrawerQuantityUpdate = (quantity: number) => {
    setDrawerQuantity(quantity)
  }

  const handleAddToCart = async (experience: Experience, quantity: number) => {
    if (!cartUid && quantity > 0) {
      createOrder({
        append: false,
        experience: experience['@id'],
        quantity,
        target: hasPartnerCodeBulkFeature && isReady ? OrderType.CODEBULK : OrderType.INVENTORY
      })
        .then((data: Order) => {
          if (data.uid) {
            setCartUid(data.uid)
          }
        })
        .catch(captureException)
    } else if (cartUid) {
      if (timeoutId) {
        clearTimeout(timeoutId as string)
      }

      setTimeoutId(
        setTimeout(() => {
          patchOrder({
            data: {
              append: false,
              experience: experience['@id'],
              quantity
            },
            uid: cartUid
          }).catch(captureException)
        }, 1000)
      )
    }

    addToCart({ experience: experience.uid, quantity, unitPrice: experience.partnerPrice })
  }

  const cancelPaymentIfNeeded = async () => {
    const paymentUid = getPaymentUid()

    if (
      paymentUid &&
      order &&
      order?.paymentState !== 'cart' &&
      order?.paymentState !== 'cancelled' &&
      order.paymentState !== 'paid'
    ) {
      cancelPayment(paymentUid).catch(captureException)
    } else if (order?.checkoutState === 'completed' && order.paymentState === 'paid') {
      clearCart()
    }
  }

  useEffect(() => {
    if (location.pathname === '/purchase' && !!cartUid) {
      refetch().catch(captureException)
    }
  }, [location])

  useEffect(() => {
    cancelPaymentIfNeeded().catch(captureException)
  }, [order])

  const onPurchaseClick = () => {
    if (getTotal() >= 50) {
      navigate('/purchase/checkout')
    } else {
      setOpenModalMinimum(true)
    }
  }

  return (
    <Layout wide>
      <Guard errorPage={false}>
        <div>
          <div className='mx-auto max-w-7xl px-2 pb-20 sm:px-6 lg:px-8'>
            <div className='mt-10 text-center'>
              <h2 className='text-2xl font-bold'>Buy codes</h2>

              <p className='my-2'>Select the Attraction and Buy It</p>
            </div>

            <div>
              <div className='my-10 grid grid-cols-6 gap-6'>
                {experiences?.data.map((experience) => {
                  const quantityInCart =
                    items.find((item) => item.experience === experience.uid)?.quantity || 0

                  return (
                    <ExperienceCard
                      experience={experience}
                      key={experience.name}
                      onAddClick={handleAddToCart}
                      onDetailsClick={handleDetailsClick}
                      quantityInCart={quantityInCart}
                    />
                  )
                })}
              </div>
            </div>
          </div>

          <div className='fixed bottom-0 grid h-20 w-full grid-cols-3 items-center border border-b-0 border-t-black/10 bg-white/50 py-4 backdrop-blur'>
            <div />

            <div className='flex flex-col items-center justify-center'>
              <button
                className={classNames(
                  'flex justify-center rounded-full border-0 px-20 py-3 text-white',
                  {
                    'bg-gray-400 cursor-not-allowed': getTotal() <= 0,
                    'bg-primary': getTotal() > 0
                  }
                )}
                onClick={onPurchaseClick}
              >
                BUY NOW
              </button>
            </div>

            <div className='flex justify-end space-x-4 pr-10'>
              <span>TOTAL </span>

              <span className='font-semibold text-primary'>
                {`${(getTotal() / 100).toFixed(
                  2
                )} €`}
              </span>
            </div>
          </div>

          <Dialog className='relative z-10' onClose={setOpenDrawer} open={openDrawer}>
            <div className='fixed inset-0' />

            <div className='fixed inset-0 overflow-hidden'>
              <div className='absolute inset-0 overflow-hidden'>
                <div className='pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10'>
                  <DialogPanel
                    className='pointer-events-auto w-screen max-w-md transition duration-500 ease-in-out data-[closed]:translate-x-full sm:duration-700'
                    transition
                  >
                    <div className='flex h-[calc(100%-5rem)] flex-col divide-y divide-gray-200 bg-white/80 shadow-xl backdrop-blur-lg'>
                      <div className='flex min-h-0 flex-1 flex-col overflow-y-scroll py-6'>
                        <div className='px-4 sm:px-6'>
                          <div className='flex items-start justify-end'>
                            <div className='ml-3 flex h-7 items-center'>
                              <button
                                className='relative rounded-full bg-white text-black hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500'
                                onClick={() => {
                                  setOpenDrawer(false)
                                }}
                                type='button'
                              >
                                <span className='absolute -inset-2.5' />

                                <span className='sr-only'>Close panel</span>

                                <XMarkIcon aria-hidden='true' className='size-6' />
                              </button>
                            </div>
                          </div>
                        </div>

                        <div className='relative mt-6 flex-1 px-4 sm:px-6'>
                          {drawerExperience && (
                            <div className='flex flex-col space-y-4'>
                              <ExperienceCard
                                drawer
                                experience={drawerExperience}
                                onAddClick={handleAddToCart}
                                onDetailsClick={handleDetailsClick}
                                quantityInCart={initialQuantityInCart ?? 1}
                                updateDrawerQuantity={handleDrawerQuantityUpdate}
                              />

                              <div className='border-t border-gray-200 py-4 text-sm'>
                                {drawerExperience.description}
                              </div>

                              <div className='py-4 text-sm'>
                                <div className='flex items-center space-x-2 border-b border-gray-200 py-2.5'>
                                  <div className='rounded-full border border-black p-0.5'>
                                    <CheckIcon className='size-4' />
                                  </div>

                                  <span className='font-bold'>
                                    Included
                                  </span>
                                </div>

                                <div className='divide-y divide-gray-200'>
                                  <div className='py-2.5'>Nombre d'oeuvres</div>

                                  <div className='py-2.5'>Nombres de visites</div>

                                  <div className='py-2.5'>Map</div>

                                  <div className='py-2.5'>Offline mode</div>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>

                      <div className='flex h-20 shrink-0 items-center justify-center bg-black/80 p-4'>
                        <button
                          className='flex items-center justify-center rounded-full border-0 bg-primary px-20 py-3 text-white'
                          onClick={() => {
                            handleDrawerAddToCart().catch(captureException)
                          }}
                          type='button'
                        >
                          {initialQuantityInCart > 0
                            ? (
                              <span>UPDATE CART</span>
                            )
                            : (
                              <span>ADD TO CART</span>
                            )}
                        </button>
                      </div>
                    </div>
                  </DialogPanel>
                </div>
              </div>
            </div>
          </Dialog>

          <Modal center open={openModalMinimum} setOpen={setOpenModalMinimum} title='Order not valid'>
            <div className='flex flex-col items-center'>
              <p className='mt-2 text-center'>Please note that the minimum order amount is 0,50 €.</p>
            </div>
          </Modal>
        </div>
      </Guard>
    </Layout>
  )
}

export default Purchase
