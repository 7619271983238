import { CloudArrowDownIcon, PrinterIcon } from '@heroicons/react/24/outline'
import html2canvas from 'html2canvas'
import { useRef } from 'react'
import QRCode from 'react-qr-code'

import Modal from '@components/modal/modal'
import { type Booking } from '@interfaces/api/booking'

interface BookingModalQRCodeProps {
  booking: Booking
  open: boolean
  setOpen: (open: boolean) => void
}

const BookingModalQrcode = ({ booking, open, setOpen }: BookingModalQRCodeProps) => {
  const qrRef = useRef<HTMLDivElement>(null)

  const downloadQRCode = async () => {
    const element = qrRef.current

    if (element) {
      const canvas = await html2canvas(element, {
        onclone: (document) => {
          const hiddenDiv = document.getElementById('render')
          if (hiddenDiv) {
            hiddenDiv.style.display = 'block'
          }
        },
        scale: 2.0
      })

      const data = canvas.toDataURL('image/jpg')
      const link = document.createElement('a')
      const fileName = `QRCode-${booking.reference}.jpg`

      if (typeof link.download === 'string') {
        link.href = data
        link.download = fileName

        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      } else {
        window.open(data)
      }
    }
  }

  const printQRCode = () => {
    const qrPrintElement = qrRef.current
    if (qrPrintElement) {
      const newWin = window.open('', 'Print-Window')
      if (newWin) {
        newWin.document.open()
        newWin.document.write(`
        <html>
          <head>
            <style>
              body { margin: 0; display: flex; justify-content: center; align-items: center; height: 100vh; }
              .qr-container { padding: 20px; }
            </style>
          </head>
          <body onload="window.print()">
            <div class="qr-container">${qrPrintElement.innerHTML}</div>
          </body>
        </html>`)
        newWin.document.close()
        setTimeout(() => {
          newWin.close()
        }, 10)
      }
    }
  }

  return (
    <Modal center open={open} setOpen={setOpen} size='xlarge' title={'QRCode Booking'}>
      <div className='flex flex-col items-center'>
        <p className='mt-2 text-center text-sm'>
          All your unlocking codes are included in this QR CODE.
          <br />
          All your customer has to do to unlock his
          <br />
          Audio Guide is simply scan it.
          <br />
        </p>

        <div className='my-5'>
          <QRCode className='size-40' value={`https://citycrushtravel.com/bookings/ext/${booking.reference}`} />
        </div>

        <div className='hidden' id='render' ref={qrRef}>
          <div className='p-10' >
            <QRCode className='size-40' value={`https://citycrushtravel.com/bookings/ext/${booking.reference}`} />
          </div>
        </div>

        <div className='flex flex-col'>
          <button
            className='mt-2 flex w-full justify-center space-x-2 rounded-full border-0 bg-primary px-20 py-2 uppercase text-white'
            onClick={downloadQRCode}
            type='button'
          >
            <span>Download</span>
          </button>

          <div className='mt-4 flex justify-center space-x-2'>
            <button onClick={printQRCode} type={'button'}>
              <PrinterIcon className='size-5' />
            </button>

            <button onClick={downloadQRCode} type={'button'}>
              <CloudArrowDownIcon className='size-5' />
            </button>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default BookingModalQrcode
