import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import ButtonPlus from '@components/button-plus/button-plus'
import Guard from '@components/guard/guard'
import Layout from '@components/layout/layout'
import Table from '@components/table/table'
import { type TableHeader } from '@components/table/table.interfaces'
import BookingListEntry from '@pages/bookings/list/booking-list-entry/booking-list-entry'
import useGetBookings from '@services/api/use-get-bookings'
import { captureException } from '@services/exceptions/capture-exception'

const headers: TableHeader[] = [
  {
    name: 'startTimeLocal',
    sort: true,
    sortDefault: 'DESC'
  },
  {
    name: 'experience',
    sort: true
  },
  {
    name: 'qty'
  },
  {
    filter: 'string',
    name: 'reference'
  },
  {
    name: 'lang'
  },
  {
    filter: 'string',
    name: 'customerLastName'
  },
  {
    name: 'customerFirstName'
  },
  {
    filter: 'string',
    name: 'customerEmail'
  },
  {
    name: 'customerPhone'
  },
  {
    filter: 'array',
    name: 'state'
  },
  {
    name: 'createdAt',
    sort: true,
    sortDefault: 'DESC'
  },
  {
    name: 'actions'
  }
]
const BookingsList = () => {
  const [searchParams, setSearchParams] = useSearchParams({
    ...Object.assign({
      'order[startTimeLocal]': 'DESC'
    })
  })
  const [itemsPerPage, setItemsPerPage] = useState(
    parseInt(searchParams.get('itemsPerPage') ?? '20') ?? 20
  )
  const pageIndex = Number(searchParams.get('page') ?? '1')

  const {
    data: {
      'hydra:member': bookings = [],
      'hydra:totalItems': totalItems = 0,
      'hydra:view': pagination = undefined
    } = {},
    isLoading,
    refetch
  } = useGetBookings({
    parameters: searchParams
  })

  useEffect(() => {
    refetch().catch(captureException)
  }, [searchParams])

  const setPageIndex = (index: number) => {
    setSearchParams({
      ...Object.fromEntries([...searchParams]),
      page: `${index}`
    })
  }

  return (
    <Layout wide>
      <Guard errorPage={false}>
        <>
          <div className='mx-auto max-w-7xl px-2 pb-20 sm:px-6 lg:px-8'>
            <div className='mt-10 grid grid-cols-3 items-center'>
              <div />

              <div className='text-center'>
                <h2 className='text-2xl font-bold'>My booking</h2>

                <p className='my-2'>List of all your bookings</p>
              </div>

              <div className='flex justify-end'>
                <ButtonPlus href={'/booking/new'} text='Create new booking' theme='light' />
              </div>
            </div>

            <div>
              <Table
                className='mt-4'
                definitionName='bookings'
                headers={headers}
                isLoading={isLoading}
                itemsPerPage={itemsPerPage}
                pageIndex={pageIndex}
                pagination={pagination}
                setItemsPerPage={setItemsPerPage}
                setPageIndex={setPageIndex}
                totalItems={totalItems}
              >
                {bookings?.map((booking) => <BookingListEntry booking={booking} key={booking.uid} />)}
              </Table>
            </div>
          </div>
        </>
      </Guard>
    </Layout>
  )
}

export default BookingsList
