import { useMutation } from '@tanstack/react-query'

import useAxios from '@services/api/axios'

interface CreatePaymentParameters {
  partnerOrder: string
}

const useCreatePayment = () => {
  const { post } = useAxios()

  return useMutation({
    mutationFn: async (data: CreatePaymentParameters) => {
      return await post<never, never>('api/partner-realm/payments', data as never)
    }
  })
}

export default useCreatePayment
