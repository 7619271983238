import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/24/outline'
import classNames from 'classnames'
import { ErrorMessage, Field, useField } from 'formik'
import React, { Fragment, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import {
  type FormSelectFloatingProps,
  type Option
} from '@components/booking-form/form-select-floating/form-select-floating.interfaces'

const FormSelectFloating = ({
  defaultValue,
  label,
  name,
  options,
  placeholder = '',
  required = false
}: FormSelectFloatingProps) => {
  const { t } = useTranslation()
  const [value, , helpers] = useField({ name })
  const { setValue } = helpers
  const [currentOption, setCurrentOption] = useState<Option>()

  useEffect(() => {
    if (defaultValue) {
      setValue(defaultValue).catch(console.error)
    }
  }, [defaultValue])

  useEffect(() => {
    if (options.length && !value.value) {
      setValue(options[0].key).catch(console.error)
      setCurrentOption(options[0])
    }
  }, [options])

  useEffect(() => {
    setCurrentOption(options.find((item) => item.key === value.value))
  }, [value])

  return (
    <div className='flex flex-1 flex-col gap-2'>
      <Listbox onChange={setValue} value={value.value}>
        {({ open }) => (
          <>
            {label && (
              <Listbox.Label className='absolute start-1 top-2 z-10 origin-[0] -translate-y-4 scale-75 bg-white px-4 text-sm text-black duration-300 peer-placeholder-shown:top-1/2 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:scale-100 peer-focus:top-2 peer-focus:-translate-y-4 peer-focus:scale-75 peer-focus:px-2 peer-focus:text-primary rtl:peer-focus:left-auto rtl:peer-focus:translate-x-1/4'>
                {t(label)}
              </Listbox.Label>
            )}
            <Listbox.Button className='relative w-full rounded-full border border-gray-300 px-4 pb-2.5 pt-4 text-left text-gray-900 shadow-sm focus:outline-none sm:text-sm'>
              <span className='block space-x-1 truncate'>{currentOption?.name ?? ''}</span>

              <span className='pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2'>
                <ChevronUpDownIcon aria-hidden='true' className='size-5 text-gray-400' />
              </span>
            </Listbox.Button>

            <Transition
              as={Fragment}
              leave='transition ease-in duration-100'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'
              show={open}
            >
              <Listbox.Options className='absolute z-20 mt-10 max-h-60 w-full overflow-auto rounded-xl bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm'>
                {options?.map((option) => (
                  <Listbox.Option
                    className={({ active, selected }) =>
                      classNames(
                        'relative cursor-default select-none py-2 pl-3 pr-9 text-gray-900',
                        { 'bg-slate-200': active }
                      )
                    }
                    key={option.key}
                    value={option.key}
                  >
                    {({ selected }) => (
                      <span className='block space-x-1 truncate font-normal'>
                        <span>{option.name}</span>

                        {selected && (
                          <span className='absolute inset-y-0 right-0 flex items-center pr-4'>
                            <CheckIcon aria-hidden='true' className='size-5' />
                          </span>
                        )}
                      </span>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </>
        )}
      </Listbox>

      <Field as='select' className='hidden' name={name} required={required} />

      <ErrorMessage className='mt-2 text-xs font-medium text-red-600' component='div' name={name} />
    </div>
  )
}

export default FormSelectFloating
