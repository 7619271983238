import { BookingsList, OnboardingTracker } from '@pages'
import { useEffect, useState } from 'react'

import { useMe } from '@services/api/auth/use-me'

const Bookings = () => {
  const [finishedOnboarding, setFinishedOnboarding] = useState(false)
  const [loadingFinished, setLoadingFinished] = useState(false)
  const { data: me } = useMe()
  useEffect(() => {
    if (me) {
      const isFormFilled =
        me?.companyName !== null &&
        me?.companyRegistrationNumber !== null &&
        me?.taxNumber !== null &&
        me?.address?.addressLine1 !== null &&
        me?.address?.postalCode !== null &&
        me?.address?.city !== null

      setFinishedOnboarding(isFormFilled)
      setLoadingFinished(true)
    }
  }, [me])

  if (!loadingFinished) {
    return <></>
  }

  if (finishedOnboarding && me?.verified && me?.validated) {
    return <BookingsList />
  }

  return <OnboardingTracker finishedOnboarding={finishedOnboarding} />
}

export default Bookings
