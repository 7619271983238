import { TrashIcon } from '@heroicons/react/24/outline'
import { PlusIcon } from '@heroicons/react/24/solid'
import classNames from 'classnames'
import { ErrorMessage } from 'formik'
import { useEffect, useRef, useState } from 'react'

import { type FormFileFieldProps } from '@components/form-image-field'

const FormImageField = ({ backgroundColor, horizontal = false, label, name, previewCover = true, required = false, setFieldValue, value }: FormFileFieldProps) => {
  const inputRef = useRef<HTMLInputElement>(null)
  const [imagePreview, setImagePreview] = useState<string>()

  useEffect(() => {
    if (!imagePreview) {
      setImagePreview(value?.url)
    }
  }, [value])

  const handleChange = (event) => {
    const file = event?.currentTarget?.files[0]
    setFieldValue(name, file)
    const fileUrl = URL.createObjectURL(file)
    setImagePreview(fileUrl)
  }

  const removeFile = () => {
    setFieldValue(name, null)
    setImagePreview('')
    if (inputRef.current?.value) {
      inputRef.current.value = ''
    }
  }

  return (
    <div className={classNames(
      'flex flex-col',
      {
        'items-center': !horizontal,
        'items-start': horizontal
      }
    )}
    >
      <label className='mb-5 block text-sm font-bold text-gray-700' htmlFor={name}>{label}</label>

      {imagePreview
        ? (
          <div className={`group flex size-40 justify-between overflow-hidden rounded-full border-2 border-gray-100 p-2 ${backgroundColor ?? ''}`}>
            <div className='relative flex size-full items-center justify-center'>
              <img
                alt='preview'
                className={classNames(
                  'rounded-full',
                  {
                    'max-w-[80%]': !previewCover,
                    'size-full object-cover object-center': previewCover
                  }
                )}
                src={imagePreview}
              />

              <button className='absolute right-3 top-3 z-10 rounded-full bg-black p-1.5 text-white hover:bg-red-500 hover:fill-white' onClick={removeFile}>
                <TrashIcon className='size-3' />
              </button>
            </div>
          </div>
        )
        : (
          <div className='group relative flex size-40 justify-between rounded-full border-2 border-gray-100 px-3 py-6 placeholder:text-gray-400 focus:outline-none'>
            <input
              className='absolute left-0 top-0 size-full cursor-pointer opacity-0'
              name={name}
              onChange={handleChange}
              ref={inputRef}
              required={required}
              type='file'
            />

            <div className='flex w-full flex-col items-center justify-center gap-1 text-center'>
              <div className='rounded-full bg-black p-1'>
                <PlusIcon className='size-7 text-white' />
              </div>
            </div>
          </div>
        )
      }

      <ErrorMessage className='mt-2 text-xs font-medium text-red-600' component='div' name={name} />
    </div>
  )
}

export default FormImageField
