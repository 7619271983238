import {
  useMutation
} from '@tanstack/react-query'

import useAxios from '@services/api/axios'

interface DeleteSlideshowItemParameters {
  uid: string
}
const useDeleteSlideshowItem = () => {
  const { del } = useAxios()

  return useMutation({
    mutationFn: async ({ uid }: DeleteSlideshowItemParameters) => {
      await del(`api/partner-realm/slideshow-items/${uid}`).then((body) => body)
    }
  })
}

export default useDeleteSlideshowItem
