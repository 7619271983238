import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline'
import { useSearchParams } from 'react-router-dom'

import { type TableSortButtonProps } from '@components/table/table-sort-button/table-sort-button.interfaces'

const TableSortButton = ({ header }: TableSortButtonProps) => {
  const [searchParams, setSearchParams] = useSearchParams()

  const currentValue = searchParams.get(`order[${header.name}]`) ?? header.sortDefault ?? 'ASC'

  const onClick = () => {
    let newValue = ''
    if (currentValue === 'ASC') {
      newValue = 'DESC'
    } else {
      newValue = 'ASC'
    }

    setSearchParams({
      [`order[${header.name}]`]: newValue
    })
  }

  return (
    <button className='rounded text-[10px]' onClick={onClick}>
      {currentValue === 'DESC'
        ? (
          <ChevronDownIcon className='size-5' />
        )
        : (
          <ChevronUpIcon className='size-5' />
        )}
    </button>
  )
}

export default TableSortButton
