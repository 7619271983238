import { useEffect, useState } from 'react'

import { useMe } from '@services/api/auth/use-me'

export enum Features {
  BOOKING = 'booking',
  CODE_BULK = 'code_bulk',
  PARTNER_CODE_BULK = 'partner_code_bulk',
  SLIDESHOW = 'slideshow',
}

interface FeatureFlags {
  hasBookingFeature: boolean
  hasCodeBulkFeature: boolean
  hasPartnerCodeBulkFeature: boolean
  hasSlideshowFeature: boolean
  isReady: boolean
}

export const useFeatures = (): FeatureFlags => {
  const { data: me, isLoading } = useMe()
  const [featureFlags, setFeatureFlags] = useState<FeatureFlags>({
    hasBookingFeature: false,
    hasCodeBulkFeature: false,
    hasPartnerCodeBulkFeature: false,
    hasSlideshowFeature: false,
    isReady: false
  })

  useEffect(() => {
    if (!isLoading && me) {
      setFeatureFlags({
        hasBookingFeature: me.features.includes(Features.BOOKING),
        hasCodeBulkFeature: me.features.includes(Features.CODE_BULK),
        hasPartnerCodeBulkFeature: me.features.includes(Features.PARTNER_CODE_BULK),
        hasSlideshowFeature: me.features.includes(Features.SLIDESHOW),
        isReady: true
      })
    } else if (!isLoading) {
      setFeatureFlags(prevFlags => ({ ...prevFlags, isReady: true }))
    }
  }, [me, isLoading])

  return featureFlags
}
