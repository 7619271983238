import React from 'react'

import { type Inventory } from '@interfaces/api/inventory'

interface InventoryListEntryProps {
  inventory: Inventory
}
const InventoryListEntry = ({ inventory }: InventoryListEntryProps) => {
  return (
    <div className='group flex cursor-pointer items-center justify-between rounded-full border border-gray-50 bg-white py-1 text-sm hover:bg-gray-30' key={inventory.uid}>

      <div className='flex w-1/2 items-center space-x-5'>
        <div className='px-3 py-2'>
          {inventory.experience.cover
            ? (
              <img
                alt={inventory.experience.name}
                className='size-14 rounded-full object-cover transition-opacity duration-200 ease-in-out group-hover:opacity-70'
                src={inventory.experience.cover.url}
              />
            )
            : (
              <div
                className='size-14 rounded-full bg-gray-200 transition-opacity duration-200 ease-in-out hover:opacity-70'
              />
            )}
        </div>

        <div className='px-3 py-2'>
          <div className='flex flex-col'>
            <span>{inventory.experience.name}</span>

            <span className='text-primary'>{inventory.experience.city.name}</span>
          </div>
        </div>
      </div>

      <div className='flex w-1/2 items-center justify-between pr-4'>
        <div className='space-x-1 px-3 py-2'>
          <span>Used</span>

          <span className='font-bold'>{inventory.used}</span>
        </div>

        <div className='space-x-1 px-3 py-2'>
          <span>Stock</span>

          <span className='font-bold text-primary'>
            x
            {inventory.stock}
          </span>
        </div>

        <div className='px-3 py-2' />
      </div>
    </div>
  )
}

export default InventoryListEntry
