import { CheckCircleIcon } from '@heroicons/react/20/solid'
import { useState } from 'react'

import Button from '@components/button/button'
import Guard from '@components/guard/guard'
import Layout from '@components/layout/layout'
import Modal from '@components/modal/modal'
import { useMe } from '@services/api/auth/use-me'
import useSendVerificationEmail from '@services/api/auth/use-send-verification-email'
import { captureException } from '@services/exceptions/capture-exception'

interface OnboardingTrackerProps {
  finishedOnboarding: boolean
}

const OnboardingTracker = ({ finishedOnboarding }: OnboardingTrackerProps) => {
  const { data: me } = useMe()
  const [openEmailVerificationModal, setOpenEmailVerificationModal] = useState(false)
  const [verificationEmailSent, setVerificationEmailSent] = useState(false)
  const { isPending, mutateAsync: sendVerificationEmail } = useSendVerificationEmail()
  const openEmailValidationModal = () => {
    setOpenEmailVerificationModal(true)
  }

  const sendNewEmail = () => {
    sendVerificationEmail().then(() => {
      setVerificationEmailSent(true)
    }).catch(captureException)
  }

  const steps = [
    { href: '/', name: 'Create account', status: 'complete' },
    {
      href: '/onboarding',
      name: 'Company information',
      status: finishedOnboarding ? 'complete' : 'current'
    },
    { name: 'Confirm your email address', onClick: openEmailValidationModal, status: me?.verified ? 'complete' : 'current' },
    {
      href: '/',
      name: 'Validation by our compliance team',
      status: me?.validated && finishedOnboarding ? 'current' : 'upcoming'
    }
  ]

  const renderStep = (step) => {
    const baseClassNames = 'flex items-center space-x-3'
    const iconClassNames = 'w-6 h-6'
    const commonIcon = (
      <span className={`relative flex ${iconClassNames} shrink-0 items-center justify-center`}>
        <span aria-hidden='true' className='absolute size-4 rounded-full bg-gray-200' />

        <span className='relative block size-2 rounded-full bg-primary' />
      </span>
    )

    if (step.status === 'complete') {
      return step.onClick
        ? (
          <button className='group' key={step.name} onClick={step.onClick}>
            <span className={baseClassNames}>
              <CheckCircleIcon aria-hidden='true' className={`text-primary group-hover:text-black ${iconClassNames}`} />

              <span className='text-sm font-medium text-gray-500 group-hover:text-gray-900'>{step.name}</span>
            </span>
          </button>
        )
        : (
          <a className='group flex' href={step.href} key={step.name}>
            <span className={baseClassNames}>
              <CheckCircleIcon aria-hidden='true' className={`text-primary group-hover:text-black ${iconClassNames}`} />

              <span className='text-sm font-medium text-gray-500 group-hover:text-gray-900'>{step.name}</span>
            </span>
          </a>
        )
    }

    if (step.status === 'current') {
      return step.onClick
        ? (
          <button aria-current='step' className={baseClassNames} key={step.name} onClick={step.onClick}>
            {commonIcon}

            <span className='text-sm text-primary'>{step.name}</span>
          </button>
        )
        : (
          <a aria-current='step' className={baseClassNames} href={step.href} key={step.name}>
            {commonIcon}

            <span className='text-sm text-primary'>{step.name}</span>
          </a>
        )
    }

    return step.onClick
      ? (
        <button className='group' key={step.name} onClick={step.onClick}>
          <div className={baseClassNames}>
            <div aria-hidden='true' className={`relative flex ${iconClassNames} shrink-0 items-center justify-center`}>
              <div className='size-2 rounded-full bg-gray-300 group-hover:bg-gray-400' />
            </div>

            <p className='text-sm font-medium text-gray-500 group-hover:text-gray-900'>{step.name}</p>
          </div>
        </button>
      )
      : (
        <a className='group flex' href={step.href} key={step.name}>
          <div className={baseClassNames}>
            <div aria-hidden='true' className={`relative flex ${iconClassNames} shrink-0 items-center justify-center`}>
              <div className='size-2 rounded-full bg-gray-300 group-hover:bg-gray-400' />
            </div>

            <p className='text-sm font-medium text-gray-500 group-hover:text-gray-900'>{step.name}</p>
          </div>
        </a>
      )
  }

  return (
    <Layout>
      <Guard errorPage={false}>
        <div>
          <h2 className='text-lg font-bold'>Welcome to City Crush for professionals.</h2>

          <p className='my-4'>Our platform allows you to:</p>

          <ul className='list-disc pl-4'>
            <li>Purchase our premium audioguides for selling</li>

            <li>Send your vouchers and audioguides by email or text message</li>
          </ul>

          {!!me && (!finishedOnboarding || !me?.verified || !me?.validated) && (
            <>
              <p className='mt-4'>Before being able to process your bookings you need to complete some steps:</p>
              <ul className='my-6 space-y-4'>
                {steps.map(renderStep)}
              </ul>
            </>
          )}

          <Modal center open={openEmailVerificationModal} setOpen={setOpenEmailVerificationModal}>
            <div className='flex flex-col items-center'>
              <p className='my-4 text-center text-xl font-bold text-primary'>Confirm your email address</p>

              {!verificationEmailSent
                ? (
                  <>
                    <p className='mt-2 text-center'>
                      To confirm your email address, please click the link in the email you
                      received.
                    </p>

                    <p className='mt-2 text-center'>
                      If you did not receive the email, click the button below to request a
                      new one.
                    </p>

                    <div className='flow-root' />

                    <Button className='mt-10 w-full text-center' isLoading={isPending} onClick={sendNewEmail}>
                      Send me an email
                    </Button>
                  </>
                )
                : (
                  <>
                    <p className='mt-2 text-center'>Your email has been successfully sent. Please check your inbox to confirm your email address.</p>

                    <div className='flow-root' />

                    <Button className='mt-10 w-full text-center' onClick={() => {
                      setOpenEmailVerificationModal(false)
                    }}
                    >
                      Close
                    </Button>
                  </>
                )}
            </div>
          </Modal>
        </div>
      </Guard>
    </Layout>
  )
}

export default OnboardingTracker
