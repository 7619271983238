import { ChevronDownIcon, MagnifyingGlassIcon } from '@heroicons/react/24/outline'
import { type ChangeEvent, type ReactElement, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'

import { type TableFiltersProps } from '@components/table/table-filters/table-filters.interfaces'
import { type TableHeader } from '@components/table/table.interfaces'
import { BookingState } from '@interfaces/api/booking'

const TableFilters = ({ definitionName, headers }: TableFiltersProps) => {
  const selectRef = useRef<HTMLSelectElement>(null)
  const { t } = useTranslation('apiResources')
  const [searchParams, setSearchParams] = useSearchParams()

  const onChangeHandler =
    (header: TableHeader) => (event: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
      if (event.target.value === '') {
        searchParams.delete(header.name)
        setSearchParams(searchParams)
      } else {
        setSearchParams({
          ...Object.fromEntries([...searchParams]),
          [header.name]: event.target.value
        })
      }
    }

  const renderText = (header: TableHeader) => (
    <div
      className='flex items-center rounded-lg bg-white px-4 shadow shadow-gray-200'
      key={header.name}
    >
      <MagnifyingGlassIcon className='size-6 text-gray-500' />

      <input
        className='block w-full appearance-none py-2 pl-3 placeholder:text-base placeholder:text-gray-300 focus:outline-none sm:text-base'
        id={header.name}
        onChange={onChangeHandler(header)}
        placeholder={`${t('filters.search')} ${t([
          `filters.${definitionName}.${header.name}`,
          `filters.${header.name}`
        ]).toLowerCase()}`}
        type='text'
      />
    </div>
  )

  const renderSelect = (header: TableHeader, options) => (
    <div className='flex flex-col' key={header.name}>
      <div className='relative flex items-center rounded-lg bg-white shadow shadow-gray-200'>
        <select
          className={`block w-full min-w-[200px] cursor-pointer appearance-none rounded-lg py-2.5 pl-4 pr-10 focus:outline-none ${
            selectRef?.current?.value ? 'text-gray-900' : 'text-gray-300'
          }`}
          id={header.name}
          onChange={onChangeHandler(header)}
          ref={selectRef}
        >
          <option value={''}>
            {`${t('filters.filterBy')} ${t(
              `filters.${definitionName}.${header.name}`, `filters.${header.name}`
            ).toLowerCase()}`}
          </option>

          {options.map((entry) => (
            <option key={entry.key} value={entry.key}>
              {t(`filters.${entry.name}`)}
            </option>
          ))}
        </select>

        <ChevronDownIcon className='pointer-events-none absolute right-4 size-6 flex-none' />
      </div>
    </div>
  )

  const components: ReactElement[] = []

  headers.forEach((header) => {
    if (!header.filter) {
      return
    }

    if (header.filter === 'array') {
      if (header.name === 'state') {
        const options = Object.values(BookingState).map(state => ({
          key: state,
          name: `bookings.states.${state}`
        }))

        components.push(renderSelect(header, options))
      } else {
        components.push(renderText(header))
      }
    } else if (header.filter === 'boolean') {
      const options = [
        { key: false, name: 'boolean.false' },
        { key: true, name: 'boolean.true' }
      ]

      components.push(renderSelect(header, options))
    } else if (header.filter === 'string') {
      if (header.name === 'state' && definitionName === 'bookings') {
        const options = Object.values(BookingState).map((state) => ({
          key: state,
          name: t(`${definitionName}.${header.name}.${state}`)
        }))

        components.push(renderSelect(header, options))
      } else {
        components.push(renderText(header))
      }
    } else {
      components.push(renderText(header))
    }
  })

  return <div className='flex w-auto items-center gap-4'>{components}</div>
}

export default TableFilters
