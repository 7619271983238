import { useQuery, type UseQueryResult } from '@tanstack/react-query'

import { type ApiError } from '@interfaces/api'
import useAxios from '@services/api/axios'

const useGetCodeBulkCsv = (uid: string, shouldFetch: boolean): UseQueryResult<Blob, ApiError> => {
  const { get } = useAxios()

  return useQuery<Blob, ApiError>({
    enabled: !!uid && shouldFetch,
    queryFn: async () =>
      await get<undefined, Blob>(`api/partner-realm/code-bulks/${uid}/download-csv`),
    queryKey: ['code-bulk-csv', uid],
    retry: 5
  })
}

export default useGetCodeBulkCsv
