import { MinusIcon, TrashIcon } from '@heroicons/react/24/outline'
import { PlusIcon } from '@heroicons/react/24/solid'
import React, { useEffect, useState } from 'react'

import { type Experience } from '@interfaces/api/experience'
import { type OrderItemProps } from '@pages/purchase/checkout/order-item/order-item.interfaces'

const OrderItem = ({ experience, onAddClick, quantityInCart, unitPrice }: OrderItemProps) => {
  const [quantity, setQuantity] = useState(quantityInCart)

  useEffect(() => {
    setQuantity(quantityInCart)
  }, [quantityInCart])

  const handleDeleteClick = () => {
    onAddClick(experience, 0)
  }

  const handleDelayedAddTocart = (experience: Experience, quantity: number) => {
    onAddClick(experience, quantity)
  }

  const handleDecrease = () => {
    if (quantity > 0) {
      setQuantity(quantity - 1)
      handleDelayedAddTocart(experience, quantity - 1)
    }
  }

  const handleIncrease = () => {
    setQuantity(quantity + 1)
    handleDelayedAddTocart(experience, quantity + 1)
  }

  const handleInputChange = (e) => {
    const newQuantity = parseInt(e.target.value)
    if (!isNaN(newQuantity) && newQuantity >= 0) {
      setQuantity(newQuantity)

      handleDelayedAddTocart(experience, newQuantity)
    } else {
      setQuantity(1)
      handleDelayedAddTocart(experience, 1)
    }
  }

  return (
    <div className='flex items-center justify-between rounded-full border border-black/10 py-2 pl-2 pr-5'>
      <div className='flex items-center justify-center space-x-4'>
        <div className='group relative hover:cursor-pointer'>
          {experience.cover
            ? (
              <img
                alt={experience.name}
                className='size-16 rounded-full object-cover transition-opacity duration-200 ease-in-out group-hover:opacity-70'
                src={experience.cover.url}
              />
            )
            : (
              <div className='size-16 rounded-full bg-gray-200 transition-opacity duration-200 ease-in-out hover:opacity-70' />
            )}
        </div>

        <div className='flex flex-col'>
          <h3 className='text-wrap text-base'>{experience.name}</h3>

          <p className='text-base text-primary'>{experience?.city?.name}</p>
        </div>
      </div>

      <div className='flex items-center justify-center'>
        <p className='space-x-1'>
          <span className='font-semibold'>{`${(experience.partnerPrice / 100).toFixed(2)}€`}</span>

          <span className='text-black/50'>/ Unit</span>
        </p>

        <div className='ml-5 mr-10 flex space-x-2'>
          <button
            className='flex items-center justify-center rounded-md bg-black/5 px-1.5'
            onClick={handleDecrease}
          >
            <MinusIcon className='size-3' />
          </button>

          <input
            className='w-full rounded-md border border-gray-300 py-1 text-center text-sm'
            onChange={handleInputChange}
            type='text'
            value={quantity}
          />

          <button
            className='flex items-center justify-center rounded-md bg-black/5 px-1.5'
            onClick={handleIncrease}
          >
            <PlusIcon className='size-3' />
          </button>
        </div>

        <div className='font-semibold text-primary'>
          {`${((unitPrice * quantityInCart) / 100).toFixed(2)}€`}
        </div>

        <button className='ml-4' onClick={handleDeleteClick} type='button'>
          <TrashIcon className='size-4' />
        </button>
      </div>
    </div>
  )
}

export default OrderItem
