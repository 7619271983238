import { type UseMutationResult, useQueryClient } from '@tanstack/react-query'
import moment from 'moment/moment'
import { useState } from 'react'

import Modal from '@components/modal/modal'
import type { ApiReponseError } from '@interfaces/api'
import { type Booking } from '@interfaces/api/booking'
import BookingForm from '@pages/bookings/booking-form/booking-form'
import { useMe } from '@services/api/auth/use-me'
import usePatchBooking, { type BookingPatch } from '@services/api/use-patch-booking'
import { captureException } from '@services/exceptions/capture-exception'

interface BookingModalEditProps {
  booking: Booking
  open: boolean
  setOpen: (open: boolean) => void
}

const BookingModalEdit = ({ booking, open, setOpen }: BookingModalEditProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const { data: me } = useMe()
  const { mutateAsync: patchBooking } = usePatchBooking()
  const queryClient = useQueryClient()

  const initialValues = {
    customerEmail: booking.customerEmail,
    customerFirstName: booking.customerFirstName,
    customerLastName: booking.customerLastName,
    customerPhone: booking.customerPhone,
    date: moment(booking.startTimeLocal).format('YYYY-MM-DD'),
    experience: booking.experience.uid,
    lang: booking.lang,
    quantity: booking.quantity,
    reference: booking.reference.replace(me?.prefixCode + '-', ''),
    time: !booking.allDay ? moment(booking.startTimeLocal).format('HH:mm') : '',
    withTickets: booking.withTickets
  }

  const onSubmit = async (values, { setErrors, setSubmitting }) => {
    setIsLoading(true)

    const dateTimeString = values.time ? `${values.date}T${values.time}:00.000Z` : `${values.date}T00:00:00.000Z`

    const submitValues: BookingPatch = {
      allDay: !values.time,
      customerEmail: values.customerEmail,
      customerFirstName: values.customerFirstName,
      customerLastName: values.customerLastName,
      customerPhone: values.customerPhone,
      lang: values.lang,
      reference: `${me?.prefixCode}-${values.reference}`,
      startTimeLocal: new Date(dateTimeString).toISOString(),
      withTickets: values.withTickets
    }

    patchBooking({ data: submitValues, uid: booking.uid }).then(() => {
      setIsLoading(false)
      queryClient.refetchQueries({
        exact: true,
        queryKey: ['partner-realm/partner-bookings']
      }).catch(captureException)
      setOpen(false)
    }).catch((e) => {
      const err = e as UseMutationResult<ApiReponseError>
      if (err.data?.violations) {
        const formErrors = err.data.violations.reduce((acc, violation) => {
          acc[violation.propertyPath] = violation.message

          return acc
        }, {})

        setErrors(formErrors)
      }
      setIsLoading(false)
    })
  }

  return (
    <Modal center open={open} setOpen={setOpen} size='large' title={'Booking edit'}>
      <div className='flex flex-col items-center'>
        {me && (
          <BookingForm editMode={true} initialValues={initialValues} isLoading={isLoading} onSubmit={onSubmit} prefixCode={me?.prefixCode} />
        )}
      </div>
    </Modal>
  )
}

export default BookingModalEdit
