import { useMutation } from '@tanstack/react-query'

import {
  type SlideshowItemFormValues
} from '@pages/settings/settings-slideshow/slideshow-item-form/slideshow-item-form'
import useAxios from '@services/api/axios'

const useCreateSlideshowItem = () => {
  const { post } = useAxios()

  return useMutation({
    mutationFn: async (data: SlideshowItemFormValues) => {
      return await post<never, never>('api/partner-realm/slideshow-items', data as never)
    }
  })
}

export default useCreateSlideshowItem
