import { ErrorMessage, Field, Form, Formik, type FormikHelpers } from 'formik'
import { useEffect, useState } from 'react'
import * as Yup from 'yup'

import FormSelectExperience from '@components/booking-form/form-select-experience/form-select-experience'
import FormSelectFloating from '@components/booking-form/form-select-floating/form-select-floating'
import ButtonLoader from '@components/button-loader/button-loader'
import FormCheckField from '@components/form-check-field/form-check-field'
import FormPhoneField from '@components/form-phone-field'
import type { Inventory } from '@interfaces/api/inventory'
import useGetInventories from '@services/api/use-get-inventories'

interface BookingFormValues {
  customerEmail: string
  customerFirstName: string
  customerLastName: string
  customerPhone: string
  date: string
  experience: string
  lang: string
  quantity: number
  reference: string
  time: string
}
interface BookingFormProps {
  editMode?: boolean
  initialValues: BookingFormValues
  isLoading: boolean
  onSubmit: (values: BookingFormValues, helpers: FormikHelpers<BookingFormValues>) => void
  prefixCode: string
}

const BookingForm = ({ editMode = false, initialValues, isLoading, onSubmit, prefixCode }: BookingFormProps) => {
  const { data: inventories } = useGetInventories<Inventory>({ parameters: { pagination: false } })
  const [options, setOptions] = useState<Array<{ key: string; name: string; stock: number }>>([])

  const FormSchema = Yup.object().shape({
    customerEmail: Yup.string().email('Bad email format').required('Email is required'),
    customerFirstName: Yup.string().required('First name is required'),
    customerLastName: Yup.string().required('Last name is required'),
    customerPhone: Yup.string().required('Phone number is required'),
    date: Yup.date().required('Date is required'),
    lang: Yup.string().required('Language selection is required'),
    reference: Yup.string()
      .required('Booking reference is required')
      .matches(/^[0-9a-zA-Z]+$/, 'Booking reference must only contain letters and numbers'),
    time: Yup.string()
  })

  const validationSchema = !editMode
    ? FormSchema.shape({
      experience: Yup.string().required('Experience selection is required'),
      quantity: Yup.number()
        .required('Quantity is required')
        .positive('Quantity must be a positive number')
        .integer('Quantity must be an integer')
    })
    : FormSchema

  useEffect(() => {
    if (inventories && !options.length) {
      const formattedOptions = inventories.data.map((inventory) => ({
        key: inventory.experience.uid,
        name: inventory.experience.name,
        stock: inventory.stock
      }))
      setOptions(formattedOptions)
    }
  }, [inventories])

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      {() => {
        return (
          <Form className='my-5 flex w-10/12 flex-col items-center space-y-6'>

            <div className='grid w-full grid-cols-2 gap-2'>
              <div className='flex w-full flex-col'>
                <div className='relative'>
                  <Field
                    className='peer block w-full appearance-none rounded-full border border-gray-300 bg-transparent px-4 pb-2.5 pt-4 text-sm text-gray-900 focus:border-primary focus:outline-none focus:ring-0'
                    id='date'
                    name='date'
                    placeholder=' '
                    type='date'
                  />

                  <label
                    className='absolute start-1 top-2 z-10 origin-[0] -translate-y-4 scale-75 bg-white px-4 text-sm text-black duration-300 peer-placeholder-shown:top-1/2 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:scale-100 peer-focus:top-2 peer-focus:-translate-y-4 peer-focus:scale-75 peer-focus:px-2 peer-focus:text-primary rtl:peer-focus:left-auto rtl:peer-focus:translate-x-1/4'
                    htmlFor='date'
                  >
                    Choose a date
                  </label>
                </div>

                <ErrorMessage
                  className='mt-2 text-xs font-bold text-primary'
                  component='div'
                  name='date'
                />
              </div>

              <div className='flex w-full flex-col'>
                <div className='relative'>
                  <Field
                    className='peer block w-full appearance-none rounded-full border border-gray-300 bg-transparent px-4 pb-2.5 pt-4 text-sm text-gray-900 focus:border-primary focus:outline-none focus:ring-0'
                    id='time'
                    name='time'
                    placeholder=' '
                    type='time'
                  />

                  <label
                    className='absolute start-1 top-2 z-10 origin-[0] -translate-y-4 scale-75 bg-white px-4 text-sm text-black duration-300 peer-placeholder-shown:top-1/2 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:scale-100 peer-focus:top-2 peer-focus:-translate-y-4 peer-focus:scale-75 peer-focus:px-2 peer-focus:text-primary rtl:peer-focus:left-auto rtl:peer-focus:translate-x-1/4'
                    htmlFor='date'
                  >
                    Choose the time (optional)
                  </label>
                </div>

                <ErrorMessage
                  className='mt-2 text-xs font-bold text-primary'
                  component='div'
                  name='time'
                />
              </div>
            </div>

            {!editMode && (

              <div className='relative flex w-full flex-col'>
                <FormSelectExperience
                  defaultValue={initialValues.experience}
                  label='Choose an audioguide'
                  name={'experience'}
                  options={options}
                />
              </div>

            )}

            <div className='flex w-full flex-col'>
              <div className='relative'>
                <div
                  className='flex items-center rounded-full border border-gray-300 bg-transparent focus-within:border-primary'
                >
                  <span className='rounded-l-full py-2.5 pl-4 pt-4 text-sm font-bold text-primary'>{`${prefixCode}-`}</span>

                  <Field
                    className='peer block w-full appearance-none bg-transparent pb-2.5 pr-4 pt-4 text-sm text-gray-900 focus:outline-none focus:ring-0'
                    id='reference'
                    name='reference'
                    placeholder=' '
                  />
                </div>

                <label
                  className='absolute start-1 top-2 z-10 origin-[0] -translate-y-4 scale-75 bg-white px-4 text-sm text-black duration-300 peer-placeholder-shown:top-1/2 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:scale-100 peer-focus:top-2 peer-focus:-translate-y-4 peer-focus:scale-75 peer-focus:px-2 peer-focus:text-primary rtl:peer-focus:left-auto rtl:peer-focus:translate-x-1/4'
                  htmlFor='reference'
                >
                  Internal booking reference
                </label>
              </div>

              <ErrorMessage
                className='mt-2 text-xs font-bold text-primary'
                component='div'
                name='reference'
              />
            </div>

            {!editMode && (

              <div className='flex w-full flex-col'>
                <div className='relative'>
                  <Field
                    className='peer block w-full appearance-none rounded-full border border-gray-300 bg-transparent px-4 pb-2.5 pt-4 text-sm text-gray-900 focus:border-primary focus:outline-none focus:ring-0'
                    id='quantity'
                    name='quantity'
                    placeholder=' '
                    type='number'
                  />

                  <label
                    className='absolute start-1 top-2 z-10 origin-[0] -translate-y-4 scale-75 bg-white px-4 text-sm text-black duration-300 peer-placeholder-shown:top-1/2 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:scale-100 peer-focus:top-2 peer-focus:-translate-y-4 peer-focus:scale-75 peer-focus:px-2 peer-focus:text-primary rtl:peer-focus:left-auto rtl:peer-focus:translate-x-1/4'
                    htmlFor='quantity'
                  >
                    Number of participants
                  </label>
                </div>

                <ErrorMessage
                  className='mt-2 text-xs font-bold text-primary'
                  component='div'
                  name='quantity'
                />
              </div>
            )}

            <div className='relative flex w-full flex-col'>
              <FormSelectFloating
                label='Language'
                name='lang'
                options={[
                  { key: 'en', name: 'English' },
                  { key: 'fr', name: 'French' },
                  { key: 'es', name: 'Spanish' }
                ]}
              />
            </div>

            <div className='flex w-full flex-col'>
              <div className='relative'>
                <Field
                  className='peer block w-full appearance-none rounded-full border border-gray-300 bg-transparent px-4 pb-2.5 pt-4 text-sm text-gray-900 focus:border-primary focus:outline-none focus:ring-0'
                  id='customerLastName'
                  name='customerLastName'
                  placeholder=' '
                />

                <label
                  className='absolute start-1 top-2 z-10 origin-[0] -translate-y-4 scale-75 bg-white px-4 text-sm text-black duration-300 peer-placeholder-shown:top-1/2 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:scale-100 peer-focus:top-2 peer-focus:-translate-y-4 peer-focus:scale-75 peer-focus:px-2 peer-focus:text-primary rtl:peer-focus:left-auto rtl:peer-focus:translate-x-1/4'
                  htmlFor='customerLastName'
                >
                  Last name
                </label>
              </div>

              <ErrorMessage
                className='mt-2 text-xs font-bold text-primary'
                component='div'
                name='customerLastName'
              />
            </div>

            <div className='flex w-full flex-col'>
              <div className='relative'>
                <Field
                  className='peer block w-full appearance-none rounded-full border border-gray-300 bg-transparent px-4 pb-2.5 pt-4 text-sm text-gray-900 focus:border-primary focus:outline-none focus:ring-0'
                  id='customerFirstName'
                  name='customerFirstName'
                  placeholder=' '
                />

                <label
                  className='absolute start-1 top-2 z-10 origin-[0] -translate-y-4 scale-75 bg-white px-4 text-sm text-black duration-300 peer-placeholder-shown:top-1/2 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:scale-100 peer-focus:top-2 peer-focus:-translate-y-4 peer-focus:scale-75 peer-focus:px-2 peer-focus:text-primary rtl:peer-focus:left-auto rtl:peer-focus:translate-x-1/4'
                  htmlFor='customerFirstName'
                >
                  First name
                </label>
              </div>

              <ErrorMessage
                className='mt-2 text-xs font-bold text-primary'
                component='div'
                name='customerFirstName'
              />
            </div>

            <div className='flex w-full flex-col'>
              <div className='relative'>
                <Field
                  className='peer block w-full appearance-none rounded-full border border-gray-300 bg-transparent px-4 pb-2.5 pt-4 text-sm text-gray-900 focus:border-primary focus:outline-none focus:ring-0'
                  id='customerEmail'
                  name='customerEmail'
                  placeholder=' '
                />

                <label
                  className='absolute start-1 top-2 z-10 origin-[0] -translate-y-4 scale-75 bg-white px-4 text-sm text-black duration-300 peer-placeholder-shown:top-1/2 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:scale-100 peer-focus:top-2 peer-focus:-translate-y-4 peer-focus:scale-75 peer-focus:px-2 peer-focus:text-primary rtl:peer-focus:left-auto rtl:peer-focus:translate-x-1/4'
                  htmlFor='customerEmail'
                >
                  Email
                </label>
              </div>

              <ErrorMessage
                className='mt-2 text-xs font-bold text-primary'
                component='div'
                name='customerEmail'
              />
            </div>

            <div className='flex w-full flex-col'>
              <FormPhoneField
                label={'Phone number'}
                labelFloating
                name={'customerPhone'}
              />
            </div>

            <div className='flex w-full flex-col'>
              <FormCheckField name='withTickets'>
                I want to send tickets with the audioguide :
              </FormCheckField>
            </div>

            <p className='text-center text-xs'>
              Your customer will receive a confirmation email and text message. They will
              then be able to download the application and access the audioguide. You will
              see your booking and be able to attach the tickets that will be available on
              our application. In the event of cancellation, the code will be deactivated,
              the content deleted and your account credited again.
            </p>

            <button
              className='mt-5 flex items-center justify-center space-x-2 rounded-full border-0 bg-primary px-20 py-2 uppercase text-white'
              disabled={isLoading}
              type='submit'
            >
              {editMode
                ? (
                  <span>Update</span>
                )
                : (
                  <span>Create & send now</span>
                )}

              {isLoading && <ButtonLoader />}
            </button>
          </Form>
        )
      }}
    </Formik>
  )
}
export default BookingForm
