import { useQuery, type UseQueryResult } from '@tanstack/react-query'

import { type ApiPlatformListResponse } from '@interfaces/api'
import { type Booking } from '@interfaces/api/booking'
import useAxios from '@services/api/axios'

interface ResourceListParameters {
  parameters?: Record<string, boolean | number | string> | URLSearchParams
}

const useGetBookings = <T extends Booking>({
  parameters
}: ResourceListParameters): UseQueryResult<ApiPlatformListResponse<T>> => {
  const { get } = useAxios()

  const queryKey = ['partner-realm/partner-bookings']
  const queryFn = async () => {
    return await get('api/partner-realm/partner-bookings', parameters)
  }
  const staleTime = 5 * 60 * 1000

  return useQuery({
    queryFn,
    queryKey,
    staleTime
  })
}

export default useGetBookings
