import { Helmet } from 'react-helmet-async'

import { type LayoutProps } from '@components/layout/layout.interfaces'
import MainMenu from '@components/menu/menu'
import Notification from '@components/notification/notification'

export default function Layout ({ children, description, title, wide = false }: LayoutProps) {
  return (
    <>
      <Helmet>
        <title>{title ?? 'City Crush Pro'}</title>

        <meta content={description} name='description' />

        <link href='/assets/theme/favicon-16.png' rel='icon' sizes='16x16' type='image/png' />

        <link href='/assets/theme/favicon-32.png' rel='icon' sizes='32x32' type='image/png' />

        <link href='/assets/theme/favicon-196.png' rel='shortcut icon' sizes='196x196' />

        <link href='/assets/theme/favicon-180.png' rel='apple-touch-icon' sizes='180x180' />
      </Helmet>

      <div className='flex size-full min-h-screen'>
        <div className='flex flex-1 flex-col overflow-auto'>
          <MainMenu />

          <main className='relative flex-1 bg-white tracking-wide'>
            <div className='mt-14 md:mt-6'>
              <div className={!wide ? 'mx-auto max-w-7xl px-2 sm:px-6 lg:px-8' : ''}>
                {children}
              </div>
            </div>
          </main>

          <Notification />
        </div>
      </div>
    </>
  )
}
