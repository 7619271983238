import classNames from 'classnames'

import Pagination from '@components/table/pagination/pagination'
import SkeletonLoaderTable from '@components/table/skeleton-loader-table/skeleton-loader-table'
import { type TableDivProps } from '@components/table/table-div.interfaces'
import TableFilters from '@components/table/table-filters/table-filters'

const TableDiv = ({
  bodyClasses,
  children,
  className,
  definitionName,
  headers,
  isLoading,
  itemsPerPage,
  pageIndex,
  pagination,
  setItemsPerPage,
  setPageIndex,
  totalItems
}: TableDivProps) => {
  return (
    <div className='mt-10'>
      {headers.some((header) => header.filter !== undefined) && (
        <TableFilters definitionName={definitionName} headers={headers} />
      )}

      <div className={classNames(`${className} mb-10 space-y-3`)}>
        {isLoading ? <SkeletonLoaderTable headers={headers} /> : children}
      </div>

      {!!pagination && (
        <Pagination
          itemsPerPage={itemsPerPage}
          pageIndex={pageIndex}
          pagination={pagination}
          setItemsPerPage={setItemsPerPage}
          setPageIndex={setPageIndex}
          totalItems={totalItems}
        />
      )}
    </div>
  )
}

export default TableDiv
