import { useMutation, type UseQueryOptions } from '@tanstack/react-query'

import useAxios from '@services/api/axios'

interface CreateOrderParameters extends Omit<UseQueryOptions, 'queryKey'> {
  data: {
    append: boolean
    experience: string
    quantity: number
  }
  uid: string
}
const usePatchOrder = () => {
  const { patch } = useAxios()

  return useMutation({
    mutationFn: async ({ data, uid }: CreateOrderParameters) => {
      return await patch<never, never>(
        `api/partner-realm/partner-orders/${uid}/update-item`,
        data as never
      )
    }
  })
}

export default usePatchOrder
