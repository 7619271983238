interface ApiResponseRegisterPassError {
  error: string
  success: 0
}

export interface ApiResponseRegisterPassSuccess {
  data: {
    refresh_token: string
    token: string
  }
  success: boolean
}

export interface ApiRequestRegisterPass {
  companyName: string
  email: string
  password: string
}

export type ApiResponseRegisterPass = ApiResponseRegisterPassError | ApiResponseRegisterPassSuccess

export function isApiResponseRegisterPassSuccess (
  response: ApiResponseRegisterPass
): response is ApiResponseRegisterPassSuccess {
  const registerPassResponse = response as ApiResponseRegisterPassSuccess

  return !!(registerPassResponse.data.token && registerPassResponse.data.refresh_token)
}
