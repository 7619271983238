import { useMutation } from '@tanstack/react-query'

import useAxios from '@services/api/axios'

interface CreateOrderParameters {
  append: boolean
  experience: string
  quantity: number
  target: OrderType
}

export enum OrderType {
  CODEBULK = 'code_bulk',
  INVENTORY = 'inventory'
}

const useCreateOrder = () => {
  const { post } = useAxios()

  return useMutation({
    mutationFn: async (data: CreateOrderParameters) => {
      return await post<never, never>('api/partner-realm/partner-orders', data as never)
    }
  })
}

export default useCreateOrder
