import { PencilSquareIcon, TrashIcon } from '@heroicons/react/24/outline'
import classNames from 'classnames'
import React from 'react'

import { type SlideshowItem } from '@interfaces/api/slideshow-item'

interface SlideshowItemRowProps {
  item: SlideshowItem
  openDeleteModal: (uid: string) => void
  openEditModal: (uid: string) => void
}
const SlideshowItemRow = ({ item, openDeleteModal, openEditModal }: SlideshowItemRowProps) => {
  return (
    <div
      className='group flex cursor-move items-center justify-between rounded-full border border-gray-50 bg-white py-1 text-sm hover:bg-gray-30'
      key={item.uid}
    >

      <div className='flex w-1/2 items-center space-x-5'>
        <div className='px-3 py-2'>
          {item.image
            ? (
              <img
                alt={item.title}
                className='size-14 rounded-full object-cover transition-opacity duration-200 ease-in-out group-hover:opacity-70'
                src={item.image.url}
              />
            )
            : (
              <div
                className='size-14 rounded-full bg-gray-200 transition-opacity duration-200 ease-in-out hover:opacity-70'
              />
            )}
        </div>

        <div className='px-3 py-2'>
          <div className='flex flex-col'>
            <span className='font-medium'>{item.title}</span>
          </div>
        </div>
      </div>

      <div className='flex w-1/2 items-center justify-between pr-4'>
        <div className='space-x-1 px-3 py-2'>
          <span className=''>{item.ctaLink}</span>
        </div>

        <div className='px-3 py-2'>
          <button
            className={classNames(
              'rounded-md p-1 hover:bg-black hover:text-white text-black'
            )}
            onClick={() => {
              openEditModal(item.uid)
            }}
            type='button'
          >
            <PencilSquareIcon className='size-5' />
          </button>

          <button
            className={classNames(
              'rounded-md p-1 hover:bg-black hover:text-white text-black'
            )}
            onClick={() => {
              openDeleteModal(item.uid)
            }}
            type='button'
          >
            <TrashIcon className='size-5' />
          </button>
        </div>
      </div>
    </div>
  )
}

export default SlideshowItemRow
