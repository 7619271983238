import Codes from '@pages/codes/codes'
import { Bookings } from '@root/pages'
import { useFeatures } from '@services/features'

const Home = () => {
  const { hasBookingFeature, hasCodeBulkFeature, hasPartnerCodeBulkFeature, isReady } = useFeatures()

  if (!isReady) {
    <div>Loading</div>
  }

  if (hasBookingFeature) {
    return <Bookings />
  }

  if (!hasBookingFeature && (hasCodeBulkFeature || hasPartnerCodeBulkFeature)) {
    return <Codes />
  }

  return (
    <div>
      {'You don\'t have enough rights to access '}
    </div>
  )
}

export default Home
