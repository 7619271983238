import { useMutation, type UseMutationResult } from '@tanstack/react-query'

import { type Slideshow } from '@interfaces/api/slideshow'
import useAxios from '@services/api/axios'

export const usePatchSlideshow = (): UseMutationResult<Slideshow, unknown, Partial<Slideshow>> => {
  const { patch } = useAxios()

  return useMutation<Slideshow, unknown, Partial<Slideshow>, unknown>({
    mutationFn: async (data) => await patch<never, never>('/api/partner-realm/slideshow', data as never)
  })
}

export default usePatchSlideshow
