import { useMutation, type UseQueryOptions } from '@tanstack/react-query'

import useAxios from '@services/api/axios'

interface UpdateBookingParameters extends Omit<UseQueryOptions, 'queryKey'> {
  data: {
    files: string[]
  }
  uid: string
}
const usePatchBookingFiles = () => {
  const { patch } = useAxios()

  return useMutation({
    mutationFn: async ({ data, uid }: UpdateBookingParameters) => {
      return await patch<never, never>(
        `api/partner-realm/partner-bookings/${uid}/files`,
        data as never
      )
    }
  })
}

export default usePatchBookingFiles
