import { useMutation } from '@tanstack/react-query'

import useAxios from '@services/api/axios'

interface CreateNotificationParameters {
  emailRecipients?: string[]
  partnerBooking: string
  smsRecipients?: string[]
}
const useCreateNotification = () => {
  const { post } = useAxios()

  return useMutation({
    mutationFn: async (data: CreateNotificationParameters) => {
      return await post<never, never>('api/partner-realm/notifications', data as never)
    }
  })
}

export default useCreateNotification
