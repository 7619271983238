import type { UseMutationResult } from '@tanstack/react-query'
import classNames from 'classnames'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import { useEffect, useRef, useState } from 'react'
import { SketchPicker } from 'react-color'
import * as Yup from 'yup'

import ButtonLoader from '@components/button-loader/button-loader'
import FormImageField from '@components/form-image-field'
import { NotificationType } from '@components/notification/notification.interfaces'
import type { ApiReponseError } from '@interfaces/api'
import type { Image } from '@interfaces/api/image'
import useGetSlideshow from '@services/api/use-get-slideshow'
import usePatchSlideshow from '@services/api/use-patch-slideshow'
import { useUploadImage } from '@services/api/use-upload-image'
import { captureException } from '@services/exceptions/capture-exception'
import { useNotificationStore } from '@services/stores/notification/notification'
import { handleImageViolations } from '@services/tools/violations'

const SUPPORTED_FORMATS = ['image/svg+xml']
const FILE_SIZE = 2 * 1024 * 1024 // 2 MB

const FormSchema = (initialLogo: Image | undefined) =>
  Yup.object({
    logo: Yup.mixed().required('Image is required').test('image-validation', 'Image is required', function (value) {
      if (value && 'uid' in value && initialLogo?.uid === value.uid) {
        return true
      }
      if (!value) {
        return false
      }

      const file = value as File
      if (file.size > FILE_SIZE) {
        return this.createError({ message: 'File too large' })
      }

      if (!SUPPORTED_FORMATS.includes(file.type)) {
        return this.createError({ message: 'Unsupported File Format' })
      }

      return true
    }),
    mainColor: Yup.string()
      .required('Main color is required')
      .length(6, 'Main color must be exactly 6 characters')
      .matches(/^[a-fA-F0-9]+$/, 'Main color must be a valid hex color code')
  })

const SlideshowForm = () => {
  const { data: slideshow } = useGetSlideshow()
  const { mutateAsync: patchSlideshow } = usePatchSlideshow()
  const { mutateAsync: uploadImage } = useUploadImage()

  const [errorMsg, setErrorMsg] = useState('')
  const [initialValues, setInitialValues] = useState({
    logo: slideshow?.logo ?? undefined,
    mainColor: ''
  })
  const [colorPreview, setColorPreview] = useState('')
  const [pickerVisible, setPickerVisible] = useState(false)
  const pickerRef = useRef<HTMLDivElement>(null)

  const { displayNotification } = useNotificationStore()

  useEffect(() => {
    setInitialValues({
      ...initialValues,
      logo: slideshow?.logo ?? undefined,
      mainColor: slideshow?.mainColor ?? ''
    })
    setColorPreview(slideshow?.mainColor ?? '')
  }, [slideshow])

  const togglePicker = () => {
    setPickerVisible(!pickerVisible)
  }

  const onSubmit = async (
    values,
    { setSubmitting }
  ) => {
    let errorMessage = ''

    setSubmitting(true)

    try {
      if (values.logo) {
        if ('@id' in values.logo) {
          values.logo = values.logo['@id']
        } else {
          const formData = new FormData()
          formData.append('file', values.logo)
          try {
            values.logo = await uploadImage(formData)
          } catch (e) {
            const err = e as UseMutationResult<ApiReponseError>
            errorMessage = handleImageViolations(err?.data?.violations ?? [])
            setErrorMsg(errorMessage)
          }
        }
      }

      await patchSlideshow({
        ...values
      })

      setSubmitting(false)

      displayNotification('Settings Updated', 'Your audioguide settings have been successfully updated.', NotificationType.success)
    } catch (err) {
      captureException(err as Error)

      setErrorMsg('An error occured')
    }
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (pickerRef.current && !pickerRef.current.contains(event.target)) {
        setPickerVisible(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={onSubmit}
      validateOnBlur={false}
      validationSchema={FormSchema(initialValues.logo)}
    >
      {({ isSubmitting, setFieldValue, values }) => {
        const handleColorChange = (e: React.ChangeEvent<HTMLInputElement>) => {
          const color = e.target.value
          if (/^[a-fA-F0-9]{6}$/.test(color)) {
            setColorPreview(color)
          } else {
            setColorPreview('')
          }
          setFieldValue('mainColor', color.toUpperCase()).catch(captureException)
        }

        const handlePickerChange = (color) => {
          const newColor = color.hex.replace('#', '')
          setColorPreview(newColor)
          setFieldValue('mainColor', newColor.toUpperCase()).catch(captureException)
        }

        return (
          <Form className='space-y-10'>
            <div className='flex flex-col'>
              <label className='text-sm font-bold' htmlFor='mainColor'>
                Main color
              </label>

              <div className='mt-2 flex items-center'>
                <div
                  className='flex rounded-lg border border-gray-200 sm:max-w-[12ch]'
                >
                  <span className='flex select-none items-center pl-3 text-gray-500 sm:text-sm'>#</span>

                  <Field
                    autoComplete='mainColor'
                    className='block w-full appearance-none rounded-lg px-3 py-2.5 text-sm placeholder:text-gray-400 focus:border-primary focus:outline-none'
                    maxLength={6}
                    name='mainColor'
                    onChange={handleColorChange}
                    placeholder='FFFFFF'
                    required
                  />
                </div>

                <button
                  className='ml-2 block size-[20px] rounded border'
                  onClick={togglePicker}
                  style={{ backgroundColor: `#${colorPreview}` }}
                  type={'button'}
                />

                <div className='relative' ref={pickerRef}>
                  <div className={classNames('absolute z-20 top-0 right-0 translate-x-[100%]', {
                    flex: pickerVisible,
                    hidden: !pickerVisible
                  })}
                  >
                    <SketchPicker color={colorPreview} onChange={handlePickerChange} onChangeComplete={handlePickerChange} />
                  </div>
                </div>

              </div>

              <ErrorMessage
                className='mt-2 text-xs font-bold text-primary'
                component='div'
                name='mainColor'
              />
            </div>

            <div className='mb-10 flex flex-col space-y-4'>
              <FormImageField
                backgroundColor={'bg-gray-300'}
                horizontal
                label='Logo'
                name={'logo'}
                previewCover={false}
                setFieldValue={setFieldValue}
                value={values.logo}
              />

              <div className='text-xs'>Svg file (max size 2mo)</div>
            </div>

            <div className='text-xs font-medium text-red-500'>{errorMsg}</div>

            <div className='flex space-x-4'>
              <button
                className='flex w-full justify-center rounded-full border-0 bg-primary py-2 text-white shadow-sm sm:text-sm sm:leading-6'
                disabled={isSubmitting}
                type='submit'
              >
                {isSubmitting ? <ButtonLoader /> : <>SAVE</>}
              </button>
            </div>
          </Form>
        )
      }}
    </Formik>
  )
}

export default SlideshowForm
