import { useMutation } from '@tanstack/react-query'

import useAxios from '@services/api/axios'

const useSendVerificationEmail = () => {
  const { post } = useAxios()

  return useMutation({
    mutationFn: async () => {
      const data = {}

      return await post<never, never>('api/partner-realm/account/send-verification-mail', data as never)
    }
  })
}

export default useSendVerificationEmail
