import { useMutation, type UseQueryOptions } from '@tanstack/react-query'

import useAxios from '@services/api/axios'

interface UpdateBookingParameters extends Omit<UseQueryOptions, 'queryKey'> {
  data: {}
  uid: string
}
const usePatchPrepareBooking = () => {
  const { patch } = useAxios()
  const data = {}

  return useMutation({
    mutationFn: async ({ data, uid }: UpdateBookingParameters) => {
      return await patch<never, never>(
        `api/partner-realm/partner-bookings/${uid}/prepare`,
        data as never
      )
    }
  })
}

export default usePatchPrepareBooking
