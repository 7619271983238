import { useQuery, type UseQueryResult } from '@tanstack/react-query'

import { type ApiError } from '@interfaces/api'
import { type Slideshow } from '@interfaces/api/slideshow'
import useAxios from '@services/api/axios'

const useGetSlideshow = (): UseQueryResult<Slideshow, ApiError> => {
  const { get } = useAxios()

  return useQuery<Slideshow, ApiError>({
    queryFn: async () =>
      await get<undefined, Slideshow>('api/partner-realm/slideshow'),
    queryKey: ['slideshow'],
    retry: 5
  })
}

export default useGetSlideshow
