import { ArrowDownOnSquareIcon, ChevronLeftIcon } from '@heroicons/react/24/outline'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { useSearchParams } from 'react-router-dom'
import slugify from 'slugify'

import Button from '@components/button/button'
import Guard from '@components/guard/guard'
import Layout from '@components/layout/layout'
import Table from '@components/table/table'
import type { TableHeader } from '@components/table/table.interfaces'
import { type Code } from '@interfaces/api/code'
import CodeListEntry from '@pages/codes/code-bulk-details/code-list-entry/code-list-entry'
import useGetCodeBulk from '@services/api/use-get-code-bulk'
import useGetCodeBulkCsv from '@services/api/use-get-code-bulk-csv'
import useGetCodeBulksCodes from '@services/api/use-get-code-bulks-codes'
import { captureException } from '@services/exceptions/capture-exception'

const headers: TableHeader[] = [
  { name: 'code' },
  { name: 'usedAt', sort: true, sortDefault: 'DESC' },
  { name: 'sentAt', sort: true, sortDefault: 'DESC' },
  { name: 'actions' }
]

const CodeBulkDetails = () => {
  const { uid } = useParams()
  const [searchParams, setSearchParams] = useSearchParams({})
  const [itemsPerPage, setItemsPerPage] = useState(parseInt(searchParams.get('itemsPerPage') ?? '20') ?? 20)
  const pageIndex = Number(searchParams.get('page') ?? '1')
  const [shouldDownload, setShouldDownload] = useState(false)
  const [localCodes, setLocalCodes] = useState<Code[]>([])

  const {
    data: { 'hydra:member': codes = [], 'hydra:totalItems': totalItems = 0, 'hydra:view': pagination = undefined } = {},
    isLoading,
    refetch
  } = useGetCodeBulksCodes({ parameters: searchParams, uid: uid ?? '' })

  const { data: codeBulk } = useGetCodeBulk(uid ?? '')

  const { data: csvData, isFetching, isSuccess: isCsvSuccess } = useGetCodeBulkCsv(uid ?? '', shouldDownload)

  useEffect(() => {
    if (codes.length > 0) {
      setLocalCodes(codes)
    }
  }, [codes])

  const updateLocalCode = (updatedCode: Code) => {
    setLocalCodes((prevCodes) =>
      prevCodes.map((code) => {
        if (code.code === updatedCode.code) {
          return { ...code, sentAt: updatedCode.sentAt }
        }

        return code
      })
    )
  }

  useEffect(() => {
    refetch().catch(captureException)
  }, [searchParams])

  useEffect(() => {
    if (isCsvSuccess && csvData && codeBulk) {
      const totalCount = codeBulk.totalCount
      const fileName = `codes_${slugify(codeBulk.experience.name, { lower: true })}_${totalCount}.csv`

      const blob = new Blob([csvData], { type: 'text/csv' })
      const url = window.URL.createObjectURL(blob)
      const a = document.createElement('a')
      a.href = url
      a.download = fileName
      document.body.appendChild(a)
      a.click()
      window.URL.revokeObjectURL(url)
      document.body.removeChild(a)
      setShouldDownload(false)
    }
  }, [csvData, isCsvSuccess, codeBulk])

  const setPageIndex = (index: number) => {
    setSearchParams({
      ...Object.fromEntries([...searchParams]),
      page: `${index}`
    })
  }

  const downloadCSV = () => {
    setShouldDownload(true)
  }

  return (
    <Layout wide>
      <Guard errorPage={false}>
        <div className='mx-auto max-w-7xl px-2 pb-20 sm:px-6 lg:px-8'>
          {codeBulk && (
            <div className='mt-10 grid grid-cols-3 items-center'>
              <div className='flex items-start'>
                <Button href='/codes' icon={ChevronLeftIcon} iconRight={false} size={'small'} />
              </div>

              <div className='text-center'>
                <h2 className='text-2xl font-bold'>
                  {codeBulk?.label && `${codeBulk.label} - `}

                  {codeBulk?.experience.name}
                </h2>

                <p className='my-2 text-xs uppercase'>{`Available codes: ${codeBulk?.remainingCount} / ${codeBulk?.totalCount}`}</p>
              </div>

              <div className='flex justify-end'>
                <Button icon={ArrowDownOnSquareIcon} isLoading={isFetching} onClick={downloadCSV} size={'medium'} style={'black'}>
                  Download CSV
                </Button>
              </div>
            </div>
          )}

          {localCodes && codeBulk && (
            <div>
              <Table
                className='mt-4'
                definitionName='codes'
                headers={headers}
                isLoading={isLoading}
                itemsPerPage={itemsPerPage}
                pageIndex={pageIndex}
                pagination={pagination}
                setItemsPerPage={setItemsPerPage}
                setPageIndex={setPageIndex}
                totalItems={totalItems}
              >
                {localCodes?.map((code) => (
                  <CodeListEntry code={code} codeBulk={codeBulk} key={code.uid} updateLocalCode={updateLocalCode} />
                ))}
              </Table>
            </div>
          )}
        </div>
      </Guard>
    </Layout>
  )
}

export default CodeBulkDetails
