import jwtDecode from 'jwt-decode'

import ErrorPage from '@components/error-page/error-page'
import { type DecodedToken, type GuardProps } from '@components/guard/guard-interfaces'
import { Roles } from '@interfaces/api/roles'
import { useAuthToken } from '@services/hooks/auth-token'

const Guard = ({
  acceptedRoles = [Roles.ROLE_PARTNER],
  children,
  errorPage = true
}: GuardProps) => {
  const { value: authToken } = useAuthToken()
  const decodedToken = jwtDecode<DecodedToken>(authToken ?? '')
  const roles = decodedToken.roles

  const isRolesInclude = roles.some((role) => acceptedRoles?.includes(role))

  if (!isRolesInclude && acceptedRoles?.length && errorPage) {
    return <ErrorPage />
  }

  if (!isRolesInclude && acceptedRoles?.length && !errorPage) {
    return null
  }

  return <div>{children}</div>
}

export default Guard
