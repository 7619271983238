import { type UseMutationResult } from '@tanstack/react-query'
import { useState } from 'react'
import { useNavigate } from 'react-router'

import Guard from '@components/guard/guard'
import Layout from '@components/layout/layout'
import { type ApiReponseError } from '@interfaces/api'
import { type Booking } from '@interfaces/api/booking'
import BookingForm from '@pages/bookings/booking-form/booking-form'
import CreateSuccessModal from '@pages/bookings/create/create-success-modal/create-success-modal'
import { useMe } from '@services/api/auth/use-me'
import useCreateBooking, { type CreateBookingParameters } from '@services/api/use-create-booking'
import usePatchPrepareBooking from '@services/api/use-patch-prepare-booking'
import { captureException } from '@services/exceptions/capture-exception'

const BookingCreate = () => {
  const { data: me } = useMe()
  const [initialValues, setInitialValues] = useState({
    customerEmail: '',
    customerFirstName: '',
    customerLastName: '',
    customerPhone: '',
    date: '',
    experience: '',
    lang: 'en',
    quantity: 1,
    reference: '',
    time: '',
    withTickets: false
  })
  const [openSuccessModal, setOpenSuccessModal] = useState <boolean>(false)
  const [noTicketsSuccessModal, setNoTicketsSuccessModal] = useState <boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const navigate = useNavigate()
  const { mutateAsync: createBooking } = useCreateBooking()
  const { mutateAsync: prepareBooking } = usePatchPrepareBooking()

  const onSubmit = async (values, { setErrors, setSubmitting }) => {
    setIsLoading(true)

    const dateTimeString = values.time ? `${values.date}T${values.time}:00.000Z` : `${values.date}T00:00:00.000Z`

    const submitValues: CreateBookingParameters = {
      allDay: !values.time,
      customerEmail: values.customerEmail,
      customerFirstName: values.customerFirstName,
      customerLastName: values.customerLastName,
      customerPhone: values.customerPhone,
      experience: `/api/partner-realm/experiences/${values.experience}`,
      lang: values.lang,
      quantity: values.quantity,
      reference: `${me?.prefixCode}-${values.reference}`,
      startTimeLocal: new Date(dateTimeString).toISOString(),
      withTickets: values.withTickets
    }

    createBooking(submitValues)
      .then((data: Booking) => {
        if (!values.withTickets && data) {
          prepareBooking({ data: {}, uid: data.uid }).then(() => {
            setIsLoading(false)
            setOpenSuccessModal(true)
            setNoTicketsSuccessModal(true)
          }).catch(captureException)
        } else {
          setIsLoading(false)
          setOpenSuccessModal(true)
        }
      }).catch((e) => {
        const err = e as UseMutationResult<ApiReponseError>
        if (err.data?.violations) {
          const formErrors = err.data.violations.reduce((acc, violation) => {
            acc[violation.propertyPath] = violation.message

            return acc
          }, {})

          setErrors(formErrors)
        }
        setIsLoading(false)
      })
  }

  const onModalSuccessClose = () => {
    setOpenSuccessModal(false)
    navigate('/bookings')
  }

  return (
    <Layout wide>
      <Guard errorPage={false}>
        <>
          <div className='mx-auto max-w-2xl px-2 pb-20 sm:px-6 lg:px-8'>
            <div className='mt-10 text-center'>
              <h2 className='text-2xl font-bold'>New booking</h2>

              <p className='my-2'>
                Please fill out all the information to provide your clients with the necessary
                elements. You can use the interface to attach your clients' tickets.
              </p>
            </div>

            <div className='flex flex-col items-center'>
              {me && (
                <BookingForm initialValues={initialValues} isLoading={isLoading} onSubmit={onSubmit} prefixCode={me?.prefixCode} />
              )}

              <CreateSuccessModal onClick={onModalSuccessClose} open={openSuccessModal} setOpen={onModalSuccessClose} withTickets={!noTicketsSuccessModal} />
            </div>
          </div>
        </>
      </Guard>
    </Layout>
  )
}

export default BookingCreate
