import { useQuery, type UseQueryResult } from '@tanstack/react-query'

import { type ApiError } from '@interfaces/api'
import { type Booking } from '@interfaces/api/booking'
import useAxios from '@services/api/axios'

const useGetBooking = (bookingUid: string, open: boolean): UseQueryResult<Booking, ApiError> => {
  const { get } = useAxios()

  return useQuery<Booking, ApiError>({
    enabled: !!bookingUid && open,
    queryFn: async () =>
      await get<undefined, Booking>(`api/partner-realm/partner-bookings/${bookingUid}`),
    queryKey: ['booking', bookingUid],
    retry: 5
  })
}

export default useGetBooking
