import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'

import ButtonLoader from '@components/button-loader/button-loader'
import Guard from '@components/guard/guard'
import Layout from '@components/layout/layout'
import useGetOrder from '@services/api/use-get-order'
import { captureException } from '@services/exceptions/capture-exception'
import { useCartStore } from '@services/stores/cart/cart'

const Success = () => {
  const { clearCart, getCartUid } = useCartStore()
  const [isOrderFulfilled, setIsOrderFulfilled] = useState(false)
  const cartUid = getCartUid()
  const { data: order, refetch } = useGetOrder(cartUid)
  const navigate = useNavigate()

  useEffect(() => {
    if (order?.state === 'fulfilled') {
      setIsOrderFulfilled(true)
      navigate('/codes')
      clearCart()
    } else {
      const interval = setInterval(() => {
        refetch().catch(captureException)
      }, 2000)

      return () => {
        clearInterval(interval)
      }
    }
  }, [order, refetch, clearCart])

  return (
    <Layout wide>
      <Guard errorPage={false}>
        <>
          <div className='mx-auto flex max-w-7xl flex-col items-center px-2 pb-28 sm:px-6 lg:px-8'>
            <div className='mt-10 text-center'>
              <h2 className='text-2xl font-bold'>Code purchase successful!</h2>

              <p className='my-2'>
                Your codes are currently being added to your inventory.
                <br />
                You will be redirected as
                soon as this process is complete.
                <br />

                <br />
                Thank you for your patience.
              </p>

              <a
                className='mt-10 flex justify-center rounded-full border-0 bg-primary px-20 py-2 uppercase text-white'
                href='/codes'
              >
                {isOrderFulfilled ? 'View my codes' : <ButtonLoader />}
              </a>
            </div>
          </div>
        </>
      </Guard>
    </Layout>
  )
}

export default Success
