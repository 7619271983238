import classNames from 'classnames'
import { useTranslation } from 'react-i18next'

import Pagination from '@components/table/pagination/pagination'
import SkeletonLoaderTable from '@components/table/skeleton-loader-table/skeleton-loader-table'
import TableFilters from '@components/table/table-filters/table-filters'
import TableSortButton from '@components/table/table-sort-button/table-sort-button'
import { type TableProps } from '@components/table/table.interfaces'

const Table = ({
  children,
  className,
  definitionName,
  headers,
  isLoading,
  itemsPerPage,
  pageIndex,
  pagination,
  setItemsPerPage,
  setPageIndex,
  totalItems
}: TableProps) => {
  const { t } = useTranslation('apiResources')

  return (
    <div className='mt-10'>
      {headers.some((header) => header.filter !== undefined) && (
        <TableFilters definitionName={definitionName} headers={headers} />
      )}

      <div className={classNames(`${className} mb-10`)}>
        <table className='min-w-full divide-y divide-gray-50'>
          <thead className='bg-gray-30'>
            <tr className='px-3 py-3.5 text-left text-xs font-bold uppercase text-black'>
              {headers.map((header) => {
                return (
                  <th className={'p-0 px-1 text-left'} key={`header-${header.name}`}>
                    <div className='flex items-start'>
                      <div className={'flex items-center justify-between gap-2 whitespace-nowrap p-3'}>
                        {t([
                          `labels.${definitionName}.${header.name}`,
                          `colsLabels.${definitionName}.${header.name}`,
                          `colsLabels.${header.name}`,
                          `labels.${header.name}`
                        ])}

                        {header.sort && <TableSortButton header={header} />}
                      </div>
                    </div>
                  </th>
                )
              })}
            </tr>
          </thead>

          <tbody className='divide-y divide-gray-50'>
            {isLoading ? <SkeletonLoaderTable headers={headers} /> : children}
          </tbody>
        </table>
      </div>

      {!!pagination && (
        <Pagination
          itemsPerPage={itemsPerPage}
          pageIndex={pageIndex}
          pagination={pagination}
          setItemsPerPage={setItemsPerPage}
          setPageIndex={setPageIndex}
          totalItems={totalItems}
        />
      )}
    </div>
  )
}

export default Table
