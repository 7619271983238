import LogoForm from '@components/forms/logo-form/logo-form'
import Guard from '@components/guard/guard'
import Layout from '@components/layout/layout'
import SettingsMenu from '@pages/settings/settings-menu'

const SettingsLogo = () => {
  const afterSubmit = () => {

  }

  return (
    <Layout>
      <Guard>
        <div className='mx-auto max-w-2xl px-2 pb-20 sm:px-6 lg:px-8'>
          <div className='text-center'>
            <h2 className='text-3xl font-bold'>Settings</h2>

            <p className='my-2' />
          </div>

          <div className='my-10'>
            <SettingsMenu />
          </div>

          <div className='mt-10'>
            <LogoForm afterSubmit={afterSubmit} editMode />
          </div>

        </div>
      </Guard>
    </Layout>
  )
}

export default SettingsLogo
