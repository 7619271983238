import { useMutation } from '@tanstack/react-query'

import useAxios from '@services/api/axios'

export interface CreateBookingParameters {
  allDay: boolean
  customerEmail: string
  customerFirstName: string
  customerLastName: string
  customerPhone: string
  experience: string
  lang: boolean
  quantity: number
  reference: string
  startTimeLocal: string
  withTickets: boolean
}
const useCreateBooking = () => {
  const { post } = useAxios()

  return useMutation({
    mutationFn: async (data: CreateBookingParameters) => {
      return await post<never, never>('api/partner-realm/partner-bookings', data as never)
    }
  })
}

export default useCreateBooking
