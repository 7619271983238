import { useQuery, type UseQueryResult } from '@tanstack/react-query'

import { type ApiError } from '@interfaces/api'
import { type SlideshowItem } from '@interfaces/api/slideshow-item'
import useAxios from '@services/api/axios'

const useGetSlideshowItem = (slideshowUid: string): UseQueryResult<SlideshowItem, ApiError> => {
  const { get } = useAxios()

  return useQuery<SlideshowItem, ApiError>({
    enabled: !!slideshowUid,
    queryFn: async () =>
      await get<undefined, SlideshowItem>(`api/partner-realm/slideshow-items/${slideshowUid}`),
    queryKey: ['slideshow-items', slideshowUid],
    retry: 5
  })
}

export default useGetSlideshowItem
