import { useMutation } from '@tanstack/react-query'

import useAxios from '@services/api/axios'

const useCancelPayment = () => {
  const { patch } = useAxios()

  return useMutation({
    mutationFn: async (uid: string) => {
      const data = {}

      return await patch<never, never>(`api/partner-realm/payments/${uid}/cancel`, data as never)
    }
  })
}

export default useCancelPayment
