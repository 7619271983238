import { NavLink } from 'react-router-dom'

import { useFeatures } from '@services/features'

const SettingsMenu = () => {
  const defaultLinkStyle = 'inline-flex items-center rounded-lg border px-5 py-2 text-sm font-semibold text-black'
  const { hasSlideshowFeature } = useFeatures()

  return (
    <div className='my-4 flex space-x-2'>
      <NavLink
        className={({ isActive }) =>
          isActive
            ? `${defaultLinkStyle} border-black bg-black text-white`
            : `${defaultLinkStyle} border-gray-200 bg-white text-black`
        }
        end
        to='/settings'
      >
        Infos
      </NavLink>

      <NavLink
        className={({ isActive }) =>
          isActive
            ? `${defaultLinkStyle} border-black bg-black text-white`
            : `${defaultLinkStyle} border-gray-200 bg-white text-black`
        }
        to='/settings/logo'
      >
        Logotype
      </NavLink>

      {hasSlideshowFeature && (
        <NavLink
          className={({ isActive }) =>
            isActive
              ? `${defaultLinkStyle} border-black bg-black text-white`
              : `${defaultLinkStyle} border-gray-200 bg-white text-black`
          }
          to='/settings/audioguide'
        >
          Audioguide
        </NavLink>
      )}
    </div>
  )
}

export default SettingsMenu
