import { CheckIcon } from '@heroicons/react/24/outline'
import classNames from 'classnames'

import Button from '@components/button/button'
import Modal from '@components/modal/modal'

interface CreateSuccessModalProps {
  onClick: () => void
  open: boolean
  setOpen: (open: boolean) => void
  withTickets: boolean
}

const CreateSuccessModal = ({ onClick, open, setOpen, withTickets }: CreateSuccessModalProps) => {
  const items = [
    { content: 'Your customer will receive a confirmation email and text message.', id: 1 },
    { content: 'They will then be able to download the application and access the audioguide.', id: 2 },
    { content: 'You will see your booking and be able to attach the tickets that will be available on our application.', id: 3 },
    { content: 'You can change it at any time before sending the tickets to your customer.', id: 4 },
    { content: 'In the event of cancellation, the code will be deactivated, the content deleted and your account credited again.', id: 5 }
  ]

  const filteredItems = withTickets ? items : items.filter(item => item.id !== 3 && item.id !== 4)

  return (
    <Modal center open={open} setOpen={setOpen} title={'Here we go !'}>
      <div className='flex flex-col items-center'>
        <p className='my-4 text-center text-primary'>Your booking has been created!</p>

        <div className='flow-root'>
          <ul className='-mb-8'>
            {filteredItems.map((item, itemIdx) => (
              <li key={item.id}>
                <div className='relative pb-4'>
                  {itemIdx !== filteredItems.length - 1
                    ? (
                      <span aria-hidden='true' className='absolute left-3.5 top-4 -ml-px h-full w-0.5 bg-gray-200' />
                    )
                    : null}

                  <div className='relative flex space-x-3'>
                    <div className='relative mt-2'>
                      <span
                        className={classNames(
                          'bg-primary',
                          'flex size-7 items-center justify-center rounded-full ring-8 ring-white'
                        )}
                      >
                        <CheckIcon aria-hidden='true' className='size-4 text-white' />
                      </span>
                    </div>

                    <div className='min-w-0 flex-1 py-1.5'>
                      <p className='text-sm text-gray-500'>{item.content}</p>
                    </div>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>

        <Button className='mt-10 w-full text-center' onClick={onClick}>
          Close
        </Button>
      </div>
    </Modal>
  )
}

export default CreateSuccessModal
