import { ErrorMessage, Field, Form, Formik, type FormikConfig } from 'formik'
import { useState } from 'react'
import { useNavigate } from 'react-router'
import * as Yup from 'yup'

import ButtonLoader from '@components/button-loader/button-loader'
import FormPasswordField from '@components/form-password-field/form-password-field'
import { useSignUpMutation } from '@services/api/auth/use-sign-up-mutation'
import { captureException } from '@services/exceptions/capture-exception'

const Register = () => {
  const navigate = useNavigate()
  const FormSchema = Yup.object({
    companyName: Yup.string().required('Required'),
    email: Yup.string().email('Bad email format').required('Required'),
    password: Yup.string().required('Required'),
    passwordConfirmation: Yup.string()
      .required('Required')
      .oneOf([Yup.ref('password')], 'Passwords must match')
  })

  const { mutateAsync: signUp } = useSignUpMutation()
  const [errorMsg, setErrorMsg] = useState('')

  const onSubmit: FormikConfig<{
    companyName: string
    email: string
    password: string
    passwordConfirmation: string
  }>['onSubmit'] = async ({ companyName, email, password }, { setSubmitting }) => {
    setSubmitting(true)

    try {
      await signUp({
        companyName,
        email,
        password
      })
      setSubmitting(false)

      navigate('/onboarding')
    } catch (err) {
      captureException(err as Error)

      setErrorMsg('Une erreur est survenue')
    }
  }

  return (
    <div className='flex min-h-full flex-1'>
      <div className='flex flex-1 flex-col justify-center px-4 py-12 sm:px-6 lg:px-20 xl:px-24'>
        <div className='mx-auto w-full max-w-sm lg:w-96'>
          <div className='flex flex-col items-center'>
            <a href='/'>
              <img alt='logo' className='h-20' src='/assets/citycrush.svg' />
            </a>

            <h2 className='mt-8 text-xl font-bold leading-9 tracking-tight text-gray-900'>
              Sign Up
            </h2>
          </div>

          <div className='mt-6'>
            <Formik
              initialValues={{
                companyName: '',
                email: '',
                password: '',
                passwordConfirmation: ''
              }}
              onSubmit={onSubmit}
              validationSchema={FormSchema}
            >
              {({ isSubmitting }) => {
                return (
                  <Form className='space-y-6'>
                    <div className='flex flex-col'>
                      <label className='text-sm font-bold' htmlFor='companyName'>
                        Company name
                      </label>

                      <Field
                        autoComplete='companyName'
                        className='mt-2 block w-full appearance-none rounded-lg border border-gray-200 px-3 py-2.5 text-sm placeholder:text-gray-400 focus:border-primary focus:outline-none'
                        name='companyName'
                        placeholder='ex: Sky Travel'
                      />

                      <ErrorMessage
                        className='mt-2 text-xs font-bold text-primary'
                        component='div'
                        name='companyName'
                      />
                    </div>

                    <div className='flex flex-col'>
                      <label className='text-sm font-bold' htmlFor='email'>
                        Email
                      </label>

                      <Field
                        autoComplete='email'
                        className='mt-2 block w-full appearance-none rounded-lg border border-gray-200 px-3 py-2.5 text-sm placeholder:text-gray-400 focus:border-primary focus:outline-none'
                        name='email'
                        placeholder='exemple@gmail.com'
                        type='email'
                      />

                      <ErrorMessage
                        className='mt-2 text-xs font-bold text-primary'
                        component='div'
                        name='email'
                      />
                    </div>

                    <div className='flex flex-col'>
                      <label className='text-sm font-bold' htmlFor='password'>
                        Password
                      </label>

                      <FormPasswordField
                        autoComplete={'current-password'}
                        name={'password'}
                        placeholder={'Your password'}
                      />

                      <ErrorMessage
                        className='mt-2 text-xs font-bold text-primary'
                        component='div'
                        name='password'
                      />
                    </div>

                    <div className='flex flex-col'>
                      <label className='text-sm font-bold' htmlFor='passwordConfirmation'>
                        Confirm password
                      </label>

                      <FormPasswordField
                        autoComplete={'current-password'}
                        name={'passwordConfirmation'}
                        placeholder={'Confirm your password'}
                      />

                      <ErrorMessage
                        className='mt-2 text-xs font-bold text-primary'
                        component='div'
                        name='passwordConfirmation'
                      />
                    </div>

                    <div className='text-xs font-medium text-red-500'>{errorMsg}</div>

                    <button
                      className='flex w-full justify-center rounded-full border-0 bg-primary py-2 text-white shadow-sm sm:text-sm sm:leading-6'
                      disabled={isSubmitting}
                      type='submit'
                    >
                      {isSubmitting ? <ButtonLoader /> : <>CONTINUE</>}
                    </button>
                  </Form>
                )
              }}
            </Formik>

            <div className='mt-10 space-x-1 text-center text-sm'>
              <span>Already have an account ?</span>

              <a className='text-primary underline' href='/login'>
                Log in
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className='relative hidden w-0 flex-1 lg:block'>
        <img
          alt=''
          className='absolute inset-0 size-full object-cover'
          src='/assets/bg-register.jpg'
        />
      </div>
    </div>
  )
}

export default Register
