import classNames from 'classnames'
import type { FieldProps } from 'formik'
import { useState } from 'react'
import { type CountryData, defaultCountries, PhoneInput as ReactIntlPhone } from 'react-international-phone'
import 'react-international-phone/style.css'

import { captureException } from '@services/exceptions/capture-exception'

interface PhoneProps extends FieldProps {
  countries?: CountryData[]
  labelFloating?: boolean
}

const PhoneInput = ({ countries, field, form, labelFloating = false }: PhoneProps) => {
  const [isFocused, setIsFocused] = useState(false)

  const handleFocus = () => {
    setIsFocused(true)
  }

  const handleBlur = () => {
    setIsFocused(false)
  }

  return (
    <ReactIntlPhone
      className={classNames('border', {
        'border-gray-300': !isFocused,
        'border-primary': isFocused,
        'rounded-full': labelFloating,
        'rounded-lg mt-2': !labelFloating
      })}
      countries={countries ?? defaultCountries}
      countrySelectorStyleProps={{
        buttonClassName: classNames('!h-full !border-none !bg-transparent', {
          '!px-2': !labelFloating,
          '!px-4': labelFloating
        }),
        dropdownStyleProps: {
          className: 'flex z-20 w-full focus:outline-none'
        }
      }}
      defaultCountry='fr'
      inputProps={{
        className: classNames('text-sm w-full focus:outline-none', {
          'rounded-full px-0 pt-3.5 pb-3': labelFloating,
          'rounded-lg px-3 py-2.5': !labelFloating
        }),
        onBlur: handleBlur,
        onFocus: handleFocus
      }}
      onChange={(value) => {
        if (!form.touched[field.name]) {
          // booking-form.setFieldTouched(field.name).catch(captureException)
        }
        form.setFieldValue(field.name, value).catch(captureException)
      }}
      value={field.value}
    />
  )
}

export default PhoneInput
