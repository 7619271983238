import { CheckIcon, TrashIcon } from '@heroicons/react/24/outline'
import { PlusIcon } from '@heroicons/react/24/solid'
import { useQueryClient } from '@tanstack/react-query'
import classNames from 'classnames'
import { ErrorMessage, Field, FieldArray, Form, Formik } from 'formik'
import { useState } from 'react'
import { defaultCountries, parseCountry } from 'react-international-phone'

import ButtonLoader from '@components/button-loader/button-loader'
import FormPhoneField from '@components/form-phone-field'
import Modal from '@components/modal/modal'
import { type Booking } from '@interfaces/api/booking'
import useCreateNotification from '@services/api/use-create-notification'
import { captureException } from '@services/exceptions/capture-exception'

interface BookingModalSendProps {
  booking: Booking
  open: boolean
  setOpen: (open: boolean) => void
}

const countries = defaultCountries.filter((country) => {
  const { iso2 } = parseCountry(country)

  return [
    'al', 'dz', 'as', 'ad', 'ao', 'ai', 'ag', 'ar', 'aw', 'at', 'az', 'bs', 'bh', 'bb', 'be',
    'bz', 'bm', 'bt', 'bo', 'ba', 'bw', 'bn', 'bg', 'bf', 'bi', 'ca', 'cv', 'ky', 'cf', 'td',
    'cl', 'cn', 'co', 'km', 'ck', 'cr', 'hr', 'cy', 'dk', 'dj', 'dm', 'do', 'ec', 'sv', 'gq',
    'ee', 'fk', 'fo', 'fj', 'fi', 'fr', 'gf', 'pf', 'ga', 'gm', 'ge', 'de', 'gi', 'gr', 'gl',
    'gd', 'gp', 'gu', 'gt', 'gg', 'gy', 'ht', 'hn', 'is', 'ie', 'im', 'it', 'jm', 'jp', 'je',
    'xk', 'kg', 'la', 'lv', 'lb', 'ls', 'ly', 'li', 'lt', 'lu', 'mo', 'mk', 'mg', 'mw', 'mv',
    'ml', 'mt', 'mq', 'mr', 'mu', 'yt', 'md', 'mc', 'mn', 'me', 'ms', 'mz', 'na', 'nr', 'nl',
    'an', 'nc', 'nz', 'ni', 'ne', 'no', 'pk', 'ps', 'pa', 'pg', 'py', 'pe', 'pl', 'pt', 'pr',
    're', 'rw', 'ws', 'sm', 'st', 'sn', 'sc', 'sl', 'sk', 'si', 'sb', 'so', 'za', 'kr', 'es',
    'kn', 'lc', 'vc', 'sr', 'se', 'ch', 'sy', 'tw', 'tj', 'tl', 'tg', 'to', 'tt', 'tm', 'tc',
    'ua', 'gb', 'us', 'uy', 'uz', 'vu', 'vg', 'ye'
  ].includes(iso2)
})

const BookingModalSend = ({ booking, open, setOpen }: BookingModalSendProps) => {
  const { mutateAsync: createNotification } = useCreateNotification()
  const [enableSMS, setEnableSMS] = useState(true)
  const [enableEmail, setEnableEmail] = useState(true)
  const [isLoading, setIsLoading] = useState(false)
  const queryClient = useQueryClient()

  const onSubmit = async (values, { resetForm, setSubmitting }) => {
    setSubmitting(true)
    setIsLoading(true)
    const submittedValues = {
      partnerBooking: booking['@id'],
      ...values
    }
    createNotification(submittedValues).then(() => {
      setIsLoading(false)
      queryClient.refetchQueries({
        exact: true,
        queryKey: ['partner-realm/partner-bookings']
      }).catch(captureException)
      setSubmitting(false)
      setOpen(false)
    }).catch(captureException)
  }

  const toggleSMS = (setFieldValue) => {
    setEnableSMS(!enableSMS)
    if (enableSMS) {
      setFieldValue('smsRecipients', [booking.customerPhone])
    }
  }

  const toggleEmail = (setFieldValue) => {
    setEnableEmail(!enableEmail)
    if (enableEmail) {
      setFieldValue('emailRecipients', [booking.customerEmail])
    }
  }

  return (
    <Modal center open={open} setOpen={setOpen} size='xlarge' title={'Send booking'}>
      <div className='flex flex-1 flex-col items-center'>
        <p className='mt-2'>Notify your customer with booking details</p>

        <Formik
          enableReinitialize
          initialValues={{
            emailRecipients: [booking.customerEmail],
            smsRecipients: [booking.customerPhone]
          }}
          onSubmit={onSubmit}
        >
          {({ isSubmitting, setFieldValue, values }) => (
            <Form className='mt-6 flex flex-1 flex-col'>
              <div className='mb-4 grid grid-cols-2 gap-4'>

                <button
                  className={classNames(
                    'inline-flex items-center space-x-2 rounded-full py-2 pl-2 pr-5 text-sm uppercase',
                    {
                      'bg-black text-white': enableSMS,
                      'bg-gray-50': !enableSMS
                    }
                  )}
                  onClick={() => {
                    toggleSMS(setFieldValue)
                  }}
                  type='button'
                >
                  {enableSMS
                    ? (
                      <span className='flex h-full shrink-0 items-center justify-center'>

                        <span className='rounded-full bg-primary p-0.5'>
                          <CheckIcon className='size-5' />
                        </span>
                      </span>
                    )
                    : (
                      <span className='flex h-full shrink-0 items-center justify-center'>

                        <span className='flex size-6 items-center justify-center rounded-full bg-white'>
                          <span className='block size-4 rounded-full border border-primary' />
                        </span>
                      </span>
                    )}

                  <span>By SMS</span>
                </button>

                <button
                  className={classNames(
                    'inline-flex items-center space-x-2 rounded-full py-2 pl-2 pr-5 text-sm uppercase',
                    {
                      'bg-black text-white': enableEmail,
                      'bg-gray-50': !enableEmail
                    }
                  )}
                  onClick={() => {
                    toggleEmail(setFieldValue)
                  }}
                  type='button'
                >
                  {enableEmail
                    ? (
                      <span className='rounded-full bg-primary p-0.5'>
                        <CheckIcon className='size-5' />
                      </span>
                    )
                    : (
                      <span className='flex size-6 items-center justify-center rounded-full bg-white'>
                        <span className='block size-4 rounded-full border border-primary' />
                      </span>
                    )}

                  <span>By Email</span>
                </button>
              </div>

              <div className='flex flex-1 flex-col justify-between'>
                <div className='grid grid-cols-2 gap-4'>
                  <FieldArray name='smsRecipients'>
                    {({ push, remove }) => (
                      <div>
                        {enableSMS && values.smsRecipients.length > 0 &&
                        values.smsRecipients.map((phone, index) => (
                          <div className='mb-2 flex items-center space-x-1' key={index}>
                            <div className='flex w-full flex-col'>
                              <FormPhoneField countries={countries} label={`Phone number ${index + 1}`} labelFloating name={`smsRecipients.${index}`} />

                              <ErrorMessage
                                className='mt-2 text-xs font-bold text-primary'
                                component='div'
                                name={`smsRecipients.${index}`}
                              />
                            </div>

                            <button
                              className='mt-2 rounded-full bg-gray-200 p-1 text-gray-600'
                              onClick={() => remove(index)}
                              type='button'
                            >
                              <TrashIcon className={'size-4'} />
                            </button>
                          </div>
                        ))}

                        {enableSMS && (
                          <button
                            className={classNames(
                              'w-full inline-flex items-center space-x-2 rounded-full py-1 pl-3 pr-5 text-sm bg-gray-50 text-black'
                            )}
                            onClick={() => {
                              push('')
                            }}
                            type='button'
                          >
                            <span className='flex h-full shrink-0 items-center justify-center'>
                              <PlusIcon className='size-6 text-black' />
                            </span>

                            <span className='grow text-center'>Add a phone number</span>
                          </button>
                        )}
                      </div>
                    )}
                  </FieldArray>

                  <FieldArray name='emailRecipients'>
                    {({ push, remove }) => (
                      <div>
                        {enableEmail && values.emailRecipients.length > 0 &&
                        values.emailRecipients.map((email, index) => (
                          <div className='mb-2 flex items-center space-x-1' key={index}>
                            <div className='flex w-full flex-col'>
                              <div className='relative'>
                                <Field
                                  className='peer block w-full appearance-none rounded-full border border-gray-300 bg-transparent px-4 pb-2.5 pt-4 text-sm text-gray-900 focus:border-primary focus:outline-none focus:ring-0'
                                  id={`emailRecipients.${index}`}
                                  name={`emailRecipients.${index}`}
                                  placeholder=' '
                                />

                                <label
                                  className='absolute start-1 top-2 z-10 origin-[0] -translate-y-4 scale-75 bg-white px-4 text-sm text-black duration-300 peer-placeholder-shown:top-1/2 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:scale-100 peer-focus:top-2 peer-focus:-translate-y-4 peer-focus:scale-75 peer-focus:px-2 peer-focus:text-primary rtl:peer-focus:left-auto rtl:peer-focus:translate-x-1/4'
                                  htmlFor={`emailRecipients.${index}`}
                                >
                                  {`Email ${index + 1}`}
                                </label>
                              </div>

                              <ErrorMessage
                                className='mt-2 text-xs font-bold text-primary'
                                component='div'
                                name={`emailRecipients.${index}`}
                              />
                            </div>

                            <button
                              className='rounded-full bg-gray-200 p-1 text-gray-600'
                              onClick={() => remove(index)}
                              type='button'
                            >
                              <TrashIcon className={'size-4'} />
                            </button>
                          </div>
                        ))}

                        {enableEmail && (
                          <button
                            className={classNames(
                              'w-full inline-flex items-center space-x-2 rounded-full py-1 pl-3 pr-5 text-sm bg-gray-50 text-black'
                            )}
                            onClick={() => {
                              push('')
                            }}
                            type='button'
                          >
                            <span className='flex h-full shrink-0 items-center justify-center'>
                              <PlusIcon className='size-6 text-black' />
                            </span>

                            <span className='grow text-center'>Add an email</span>
                          </button>
                        )}
                      </div>
                    )}
                  </FieldArray>
                </div>

                <button
                  className='mt-20 flex w-full justify-center space-x-2 rounded-full border-0 bg-primary px-20 py-2 uppercase text-white'
                  disabled={isLoading}
                  type='submit'
                >
                  <span>Send</span>

                  {isLoading && <ButtonLoader />}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  )
}

export default BookingModalSend
