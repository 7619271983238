import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/outline'
import { Field } from 'formik'
import { useState } from 'react'

interface InputPasswordProps {
  autoComplete: string
  name: string
  placeholder: string
}
const FormPasswordField = ({ autoComplete, name, placeholder }: InputPasswordProps) => {
  const [showPassword, setShowPassword] = useState(false)

  const togglePassword = () => {
    setShowPassword(!showPassword)
  }

  return (
    <div className='relative mt-2'>
      <Field
        autoComplete={autoComplete}
        className='block w-full appearance-none rounded-lg border border-gray-200 px-3 py-2.5 text-sm placeholder:text-gray-400 focus:border-primary focus:outline-none'
        name={name}
        placeholder={placeholder}
        type={showPassword ? 'text' : 'password'}
      />

      <button
        className='absolute inset-y-0 right-4'
        onClick={togglePassword}
        tabIndex={-1}
        type='button'
      >
        {showPassword ? <EyeSlashIcon className='size-4' /> : <EyeIcon className='size-4' />}
      </button>
    </div>
  )
}

export default FormPasswordField
