import { ClipboardDocumentListIcon } from '@heroicons/react/24/outline'
import classNames from 'classnames'
import React, { useRef, useState } from 'react'

import { type ButtonTooltipProps } from '@components/button-tooltip/button-tooltip.interfaces'

const ButtonTooltip = ({ handleCopyClick, withBorder = false }: ButtonTooltipProps) => {
  const [tooltip, setTooltip] = useState('Copier')

  const [showTooltip, setShowTooltip] = useState(false)
  const hideTooltipTimeout = useRef<NodeJS.Timeout | null>(null)

  const handleMouseEnter = () => {
    if (hideTooltipTimeout.current) {
      clearTimeout(hideTooltipTimeout.current)
    }
    setShowTooltip(true)
  }

  const handleMouseLeave = () => {
    hideTooltipTimeout.current = setTimeout(() => {
      setShowTooltip(false)
    }, tooltip === 'Copié !' ? 1000 : 100)
  }

  const onClick = () => {
    handleCopyClick()
    setTooltip('Copié !')
    setTimeout(() => {
      setTooltip('Copier le code')
    }, 1500)
  }

  return (
    <div className='relative flex items-center justify-center'>
      <button
        className={classNames(
          'group relative flex cursor-pointer items-center justify-center gap-2 rounded-md p-1 text-sm text-gray-700 hover:bg-black hover:text-white',
          {
            'border border-gray-200': withBorder
          }
        )}
        onClick={onClick}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <ClipboardDocumentListIcon className='mx-auto size-4' />

        {showTooltip && (
          <span className='absolute bottom-full mb-2'>
            <span className='relative z-10 whitespace-nowrap rounded-md bg-black p-2 text-xs leading-none text-white shadow-lg'>
              {tooltip}
            </span>

            <div className='absolute bottom-0 left-1/2 -ml-1.5 size-3 -translate-x-1/2 rotate-45 bg-black' />
          </span>
        )}
      </button>
    </div>
  )
}

export default ButtonTooltip
