import {
  CheckIcon,
  PaperAirplaneIcon,
  PencilSquareIcon,
  QrCodeIcon
} from '@heroicons/react/24/outline'
import classNames from 'classnames'
import moment from 'moment/moment'
import { useEffect, useState } from 'react'

import ButtonLoader from '@components/button-loader/button-loader'
import ButtonTooltip from '@components/button-tooltip/button-tooltip'
import { type Booking, BookingState } from '@interfaces/api/booking'
import BookingModalEdit from '@pages/bookings/list/booking-modal-edit/booking-modal-edit'
import BookingModalFiles from '@pages/bookings/list/booking-modal-files/booking-modal-files'
import BookingModalQrcode from '@pages/bookings/list/booking-modal-qrcode/booking-modal-qrcode'
import BookingModalSend from '@pages/bookings/list/booking-modal-send/booking-modal-send'
import useGetBooking from '@services/api/use-get-booking'
import { captureException } from '@services/exceptions/capture-exception'

interface BookingListEntryProps {
  booking: Booking
}
const BookingListEntry = ({ booking }: BookingListEntryProps) => {
  const [editModalOpen, setEditModalOpen] = useState(false)
  const [qrcodeModalOpen, setQrcodeModalOpen] = useState(false)
  const [sendModalOpen, setSendModalOpen] = useState(false)
  const [filesModalOpen, setFilesModalOpen] = useState(false)
  const [triggerFilesModalOpen, setTriggerFilesModalOpen] = useState(false)
  const { isFetching, isSuccess } = useGetBooking(booking.uid, triggerFilesModalOpen)

  const onCopyClick = async (reference: string) => {
    await navigator.clipboard.writeText(reference)
  }

  const openTicketsModal = () => {
    setTriggerFilesModalOpen(true)
  }

  const openSendModal = () => {
    setSendModalOpen(true)
    setFilesModalOpen(false)
  }

  const openQrcodeModal = () => {
    setQrcodeModalOpen(true)
  }

  const openEditModal = () => {
    setEditModalOpen(true)
  }

  useEffect(() => {
    if (triggerFilesModalOpen && isSuccess) {
      setFilesModalOpen(true)
      setTriggerFilesModalOpen(false)
    }
  }, [isSuccess, triggerFilesModalOpen])

  return (
    <tr className='group cursor-pointer bg-white text-sm hover:bg-gray-30'>
      <td className='px-3 py-2'>{moment(booking.startTimeLocal).format('DD.MM.YYYY')}</td>

      <td className='px-3 py-2 font-medium'>{booking.experience.name}</td>

      <td className='px-3 py-2'>{booking.quantity}</td>

      <td className='px-3 py-2 text-xs'>
        <div className='flex items-center justify-between'>
          <span>{booking.reference}</span>

          <ButtonTooltip handleCopyClick={async () => {
            await onCopyClick(booking.reference).catch(captureException)
          }}
          />
        </div>
      </td>

      <td className='px-3 py-2 text-xs uppercase'>{booking.lang}</td>

      <td className='px-3 py-2'>{booking.customerLastName}</td>

      <td className='px-3 py-2'>{booking.customerFirstName}</td>

      <td className='px-3 py-2 text-xs'>{booking.customerEmail}</td>

      <td className='px-3 py-2 text-xs'>{booking.customerPhone}</td>

      <td className='px-3 py-2 text-xs'>
        <div className='flex items-center space-x-2 uppercase text-black/60'>
          <div className={classNames(
            'size-2 rounded-full',
            {
              'bg-green-600': booking.state === BookingState.completed,
              'bg-orange-300': booking.state === BookingState.pending,
              'bg-primary': booking.state === BookingState.ready,
              'bg-red-600': booking.state === BookingState.cancelled
            }
          )}
          />

          <span>{booking.state}</span>
        </div>
      </td>

      <td className='px-3 py-2'>{moment(booking.createdAt).format('DD.MM.YYYY')}</td>

      <td className='px-3 py-2'>
        <div className='flex items-center space-x-1'>
          {booking.withTickets && [BookingState.pending, BookingState.ready].includes(booking.state) && (
            <button
              className={classNames(
                'flex w-24 justify-center items-center space-x-2 whitespace-nowrap rounded-md px-2 py-1 text-xs font-bold text-white',
                {
                  'bg-orange-300': booking.state === BookingState.ready,
                  'bg-primary': booking.state === BookingState.pending
                }
              )}
              onClick={booking.state === BookingState.pending ? openTicketsModal : openSendModal}
              type='button'
            >
              {isFetching
                ? (
                  <ButtonLoader size='4' wrapperClassName='px-8' />
                )
                : (
                  <span>{booking.state === BookingState.ready ? 'SEND NOW' : 'ADD TICKETS'}</span>
                )}
            </button>
          )}

          {booking.withTickets && booking.state === BookingState.completed && (
            <button
              className='flex w-24 items-center justify-center space-x-1 rounded-md bg-black px-2 py-1 text-xs font-bold text-white'
              onClick={openSendModal}
              type='button'
            >
              <CheckIcon className='size-5' />

              <span>TICKETS</span>
            </button>
          )}

          {!booking.withTickets && (
            <button
              className='flex w-24 items-center justify-center space-x-1 rounded-md bg-gray-100 px-2 py-1 text-xs font-bold text-white'
              onClick={openSendModal}
              type='button'
            >
              <span>NO TICKETS</span>
            </button>
          )}

          <button
            className={classNames(
              'rounded-md p-1 text-black hover:bg-black hover:text-white',
              {
                'text-black': booking.state !== BookingState.completed && booking.state !== BookingState.cancelled,
                'text-gray-200 cursor-not-allowed': booking.state === BookingState.completed || booking.state === BookingState.cancelled
              }
            )}
            onClick={booking.state !== BookingState.completed && booking.state !== BookingState.cancelled
              ? openEditModal
              : () => {
              }}
            type='button'
          >
            <PencilSquareIcon className='size-5' />
          </button>

          <button
            className='rounded-md p-1 text-black hover:bg-black hover:text-white'
            onClick={openQrcodeModal}
            type='button'
          >
            <QrCodeIcon className='size-5' />
          </button>

          <button
            className={classNames(
              'rounded-md p-1 hover:bg-black hover:text-white text-black'
            )}
            onClick={openSendModal}
            type='button'
          >
            <PaperAirplaneIcon className='size-5' />
          </button>

          <BookingModalFiles booking={booking} onSendClick={openSendModal} open={filesModalOpen}
            setOpen={setFilesModalOpen}
          />

          <BookingModalSend booking={booking} open={sendModalOpen} setOpen={setSendModalOpen} />

          <BookingModalQrcode booking={booking} open={qrcodeModalOpen} setOpen={setQrcodeModalOpen} />

          <BookingModalEdit booking={booking} open={editModalOpen} setOpen={setEditModalOpen} />
        </div>
      </td>
    </tr>
  )
}

export default BookingListEntry
