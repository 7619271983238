import { ErrorMessage, Field } from 'formik'

import { type FormPhoneFieldProps } from '@components/form-phone-field/form-phone-field.interfaces'
import PhoneInput from '@components/form-phone-field/phone-input/phone-input'

const FormPhoneField = ({
  countries,
  label,
  labelFloating = false,
  name,
  placeholder,
  required = false
}: FormPhoneFieldProps) => {
  if (labelFloating) {
    return (
      <div className='flex w-full flex-col'>
        <div className='relative'>
          <Field
            className='peer block w-full appearance-none rounded-full border border-gray-300 bg-transparent px-4 pb-2.5 pt-4 text-sm text-gray-900 focus:border-primary focus:outline-none focus:ring-0'
            component={PhoneInput}
            countries={countries}
            id={name}
            labelFloating={labelFloating}
            name={name}
            placeholder=' '
          />

          <label
            className='absolute start-1 top-2 z-10 origin-[0] -translate-y-4 scale-75 bg-white px-4 text-sm text-black duration-300 peer-placeholder-shown:top-1/2 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:scale-100 peer-focus:top-2 peer-focus:-translate-y-4 peer-focus:scale-75 peer-focus:px-2 peer-focus:text-primary rtl:peer-focus:left-auto rtl:peer-focus:translate-x-1/4'
            htmlFor={name}
          >
            {label}

            {required && <sup className=''>*</sup>}
          </label>
        </div>

        <ErrorMessage className='mt-2 text-xs font-bold text-primary' component='div' name={name} />
      </div>
    )
  }

  return (
    <div className='flex w-full flex-col'>
      <label className='text-sm font-bold' htmlFor={name}>
        {label}

        {required && <sup className=''>*</sup>}
      </label>

      <Field component={PhoneInput} name={name} placeholder={placeholder} required={required} />

      <ErrorMessage className='mt-2 text-xs font-bold text-primary' component='div' name={name} />
    </div>
  )
}

export default FormPhoneField
