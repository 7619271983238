import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/24/outline'
import classNames from 'classnames'
import { ErrorMessage, useField } from 'formik'
import React, { Fragment, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { type FormSelectFieldProps } from '@components/form-select-field/form-select-field.interfaces'
import { captureException } from '@services/exceptions/capture-exception'

const FormSelectField = ({
  defaultValue,
  groupedField = false,
  label,
  name,
  options,
  placeholder = '',
  required = false
}: FormSelectFieldProps) => {
  const { t } = useTranslation()

  const [value, , helpers] = useField({ name })
  const { setValue } = helpers

  const displayOption = (key) => {
    const optionObj = options.find((item) => item.key === key)

    return optionObj ?? options[0]
  }

  useEffect(() => {
    if (!placeholder) {
      setValue(options[0].key).catch(captureException)
    }

    if (defaultValue) {
      setValue(defaultValue).catch(captureException)
    }
  }, [defaultValue])

  return (
    <div className={classNames('flex flex-1 flex-col', { 'gap-2': label })}>
      <Listbox onChange={setValue} value={value.value}>
        {({ open }) => (
          <>
            {label && (
              <Listbox.Label className='block text-sm font-bold text-gray-900'>
                {t(label)}

                {required && <sup>*</sup>}
              </Listbox.Label>
            )}
            <div className='relative'>
              <Listbox.Button
                className={classNames(
                  'relative flex w-full cursor-default items-center border border-gray-200 py-1.5 pl-3 pr-10 text-left text-gray-900 focus:outline-none sm:text-sm sm:leading-6',
                  {
                    'rounded-md': !groupedField,
                    'rounded-r-md border-l-0': groupedField
                  }
                )}
              >
                <span className='block truncate'>{displayOption(value.value).name}</span>

                {displayOption(value.value).image && (
                  <img
                    alt='Option'
                    className='ml-4 size-6 rounded-md bg-slate-100 object-contain p-1'
                    src={displayOption(value.value).image}
                  />
                )}

                <span className='pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2'>
                  <ChevronUpDownIcon aria-hidden='true' className='size-5 text-gray-400' />
                </span>
              </Listbox.Button>

              <Transition
                as={Fragment}
                leave='transition ease-in duration-100'
                leaveFrom='opacity-100'
                leaveTo='opacity-0'
                show={open}
              >
                <Listbox.Options className='absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm'>
                  {options.map((option) => (
                    <Listbox.Option
                      className={({ active }) =>
                        classNames(
                          active ? 'bg-slate-900 text-white' : 'text-gray-900',
                          'relative cursor-default select-none py-2 pl-3 pr-9'
                        )
                      }
                      key={option.key}
                      value={option.key}
                    >
                      {({ active, selected }) => (
                        <span className='flex items-center justify-between'>
                          <span
                            className={classNames(
                              selected ? 'font-semibold' : 'font-normal',
                              'block truncate'
                            )}
                          >
                            {option.name}
                          </span>

                          {option.image && (
                            <img
                              alt='Option'
                              className='size-6 rounded-md bg-slate-100 object-contain p-1'
                              src={option.image}
                            />
                          )}

                          {!option.image && selected
                            ? (
                              <span
                                className={classNames(
                                  active ? 'text-white' : 'text-indigo-600',
                                  'absolute inset-y-0 right-0 flex items-center pr-4'
                                )}
                              >
                                <CheckIcon aria-hidden='true' className='size-5' />
                              </span>
                            )
                            : null}
                        </span>
                      )}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            </div>
          </>
        )}
      </Listbox>

      <ErrorMessage className='mt-2 text-xs font-medium text-red-600' component='div' name={name} />
    </div>
  )
}

export default FormSelectField
