import { useMutation, type UseQueryOptions } from '@tanstack/react-query'

import useAxios from '@services/api/axios'

export interface CodeBulkPatch {
  codes: string[]
  sentAt: string | null
}
interface UpdateCodeBulkParameters extends Omit<UseQueryOptions, 'queryKey'> {
  data: CodeBulkPatch
  uid: string
}
const usePatchCodeBulk = () => {
  const { patch } = useAxios()

  return useMutation({
    mutationFn: async ({ data, uid }: UpdateCodeBulkParameters) => {
      return await patch<never, never>(
        `api/partner-realm/code-bulks/${uid}/codes`,
        data as never
      )
    }
  })
}

export default usePatchCodeBulk
