import { useNavigate } from 'react-router'

import LegalForm from '@components/forms/legal-form/legal-form'

const Legal = () => {
  const navigate = useNavigate()

  const afterSubmit = () => {
    navigate('/onboarding/logo')
  }

  return (
    <div className='flex min-h-full flex-1'>
      <div className='flex flex-1 flex-col px-4 py-12 sm:px-6 lg:px-20 xl:px-24'>
        <div className='flex justify-between'>
          <a href='/'>
            <img alt='logo' className='h-12' src='/assets/citycrush.svg' />
          </a>

          <a className='font-bold hover:underline' href='/'>
            Skip
          </a>
        </div>

        <div className='mx-auto w-full max-w-sm lg:w-96'>
          <div className='mt-10 flex flex-col items-center'>
            <h2 className='mt-8 text-3xl font-bold leading-9 tracking-tight text-gray-900'>
              Let's make it yours
            </h2>

            <p className='mt-4 text-center'>
              Add your own content below. You can also further customise your profil later.
            </p>
          </div>

          <div className='mt-6'>
            <LegalForm afterSubmit={afterSubmit} />
          </div>
        </div>
      </div>

      <div className='relative hidden w-0 flex-1 lg:block'>
        <img
          alt=''
          className='absolute inset-0 size-full object-cover'
          src='/assets/bg-register-steps.jpg'
        />
      </div>
    </div>
  )
}

export default Legal
