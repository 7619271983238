import { type SkeletonLoaderTableProps } from '@components/table/skeleton-loader-table/skeleton-loader-table.interfaces'

const SkeletonLoaderTable = ({ headers }: SkeletonLoaderTableProps) => (
  <>
    {Array.from({ length: 5 }).map((_, index) => (
      <tr key={index}>
        {headers.map((header) => (
          <td className='p-6' key={header.name}>
            <div className='max-w-sm animate-pulse' role='status'>
              <div className='h-2.5 rounded-full bg-gray-200 dark:bg-gray-500' />
            </div>
          </td>
        ))}
      </tr>
    ))}
  </>
)

export default SkeletonLoaderTable
