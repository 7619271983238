import { CloudArrowUpIcon } from '@heroicons/react/24/outline'
import { ErrorMessage, useFormikContext } from 'formik'
import { useRef } from 'react'

import ButtonLoader from '@components/button-loader/button-loader'
import {
  type BookingFilesFieldProps
} from '@pages/bookings/list/booking-files-field/booking-files-field.interfaces'
import { captureException } from '@services/exceptions/capture-exception'

const BookingFilesField = ({ accept = '.csv', hasFiles = false, isSubmitting, multiple = false, name, required = false, setFieldValue }: BookingFilesFieldProps) => {
  const inputRef = useRef<HTMLInputElement>(null)
  const formik = useFormikContext()

  const handleChange = (event) => {
    const files: File[] = event.target.files ? Array.from(event.target.files) : []
    setFieldValue(name, files)

    if (files.length > 0 && !formik.isSubmitting) {
      formik.submitForm().catch(captureException)
    }
  }

  return (
    <div className='relative order-1 flex flex-col'>
      <div className=''>
        <input
          accept={accept}
          className='absolute left-0 top-0 size-full cursor-pointer opacity-0'
          multiple
          name={name}
          onChange={handleChange}
          ref={inputRef}
          required={required}
          type='file'
        />

        <div className='flex w-full flex-col items-center justify-center gap-1 text-center'>
          {hasFiles
            ? (
              <>
                {isSubmitting
                  ? (
                    <ButtonLoader dark />
                  )
                  : (
                    <CloudArrowUpIcon className='size-10 text-black' />
                  )}

                <label className='text-center text-xs' htmlFor={name}>
                  Add tickets?
                </label>
              </>
            )
            : (
              <>
                {isSubmitting
                  ? (
                    <ButtonLoader />
                  )
                  : (
                    <CloudArrowUpIcon className='my-4 size-20 text-black' />
                  )}

                <label className='text-center text-xs' htmlFor={name}>
                  Drag & Drop Here
                  <br />
                  Files only accepted: pdf, jpg, png, gif
                  <br />
                  (5Mo Max)
                </label>
              </>
            )}
        </div>
      </div>

      <ErrorMessage className='mt-2 text-xs font-medium text-red-600' component='div' name={name} />
    </div>
  )
}

export default BookingFilesField
