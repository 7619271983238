export interface ApiResponseLoginPassError {
  code: number
  errorCode: string
  message: string
}

export interface ApiResponseLoginPassSuccess {
  refresh_token: string
  token: string
}

export interface ApiRequestLoginPass {
  email: string
  password: string
}

export type ApiResponseLoginPass = ApiResponseLoginPassError | ApiResponseLoginPassSuccess

export function isApiResponseLoginPassSuccess (
  response: ApiResponseLoginPass
): response is ApiResponseLoginPassSuccess {
  const loginPassResponse = response as ApiResponseLoginPassSuccess

  return !!(loginPassResponse.token && loginPassResponse.refresh_token)
}
