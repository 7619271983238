import { useQuery, type UseQueryResult } from '@tanstack/react-query'

import { type ApiError } from '@interfaces/api'
import { type CodeBulk } from '@interfaces/api/code-bulk'
import useAxios from '@services/api/axios'

const useGetCodeBulk = (uid: string): UseQueryResult<CodeBulk, ApiError> => {
  const { get } = useAxios()

  return useQuery<CodeBulk, ApiError>({
    enabled: !!uid,
    queryFn: async () =>
      await get<undefined, CodeBulk>(`api/partner-realm/code-bulks/${uid}`),
    queryKey: ['code-bulk', uid],
    retry: 5
  })
}

export default useGetCodeBulk
