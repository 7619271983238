import { type AxiosResponse } from 'axios'
import { ErrorMessage, Form, Formik, type FormikConfig } from 'formik'
import { useState } from 'react'
import { useParams } from 'react-router'
import * as Yup from 'yup'

import ButtonLoader from '@components/button-loader/button-loader'
import FormPasswordField from '@components/form-password-field/form-password-field'
import { type ApiReponseError } from '@interfaces/api'
import { ApiError } from '@interfaces/api/error'
import { useResetPasswordMutation } from '@services/api/auth/use-reset-password-mutation'
import { captureException } from '@services/exceptions/capture-exception'

const ResetPassword = () => {
  const [requestSuccess, setRequestSuccess] = useState(false)
  const [needNewRequest, setNeedNewRequest] = useState(false)

  const FormSchema = Yup.object({
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password'), undefined], 'Passwords must match')
      .required('Required'),
    password: Yup.string().required('Required')
  })

  const { mutateAsync: resetPassword } = useResetPasswordMutation()
  const [errorMsg, setErrorMsg] = useState('')
  const { token = '' } = useParams()

  const onSubmit: FormikConfig<{ confirmPassword: string; password: string }>['onSubmit'] = async (
    values,
    { setSubmitting }
  ) => {
    setSubmitting(true)
    setErrorMsg('')

    try {
      await resetPassword({
        data: {
          newPassword: values.password
        },
        token
      })
      setSubmitting(false)
      setRequestSuccess(true)
    } catch (err) {
      captureException(err as Error)

      const axiosResponse = err as AxiosResponse
      const error = axiosResponse.data as ApiReponseError

      if (error.errorCode === ApiError.RESET_PASSWORD_TOKEN_INVALID) {
        setNeedNewRequest(true)
        setErrorMsg('This link has expired please request another reset password link.')
      }
      // HANDLE ERROR LIKE RESET_PASSWORD_TOKEN_INVALID
      setSubmitting(false)
    }
  }

  return (
    <div className='flex min-h-full flex-1'>
      <div className='flex flex-1 flex-col justify-center px-4 py-12 sm:px-6 lg:px-20 xl:px-24'>
        <div className='mx-auto w-full max-w-sm lg:w-96'>
          <div className='flex flex-col items-center'>
            <a href='/'>
              <img alt='logo' className='h-20' src='/assets/citycrush.svg' />
            </a>

            <h2 className='mt-8 text-xl font-bold leading-9 tracking-tight text-gray-900'>
              {requestSuccess ? 'Your password has been successfully reset' : 'Let\'s reset your password' }
            </h2>
          </div>

          {!requestSuccess
            ? (
              <div className='mt-6'>
                <Formik
                  initialValues={{
                    confirmPassword: '',
                    password: ''
                  }}
                  onSubmit={onSubmit}
                  validationSchema={FormSchema}
                >
                  {({ isSubmitting }) => {
                    return (
                      <Form className='space-y-6'>
                        <div className='flex flex-col'>
                          <label className='text-sm font-bold' htmlFor='password'>
                            Password
                          </label>

                          <FormPasswordField
                            autoComplete={'current-password'}
                            name={'password'}
                            placeholder={'Your password'}
                          />

                          <div className='mt-2 flex w-full justify-between'>
                            <ErrorMessage
                              className='text-xs font-bold text-primary'
                              component='div'
                              name='password'
                            />
                          </div>
                        </div>

                        <div className='flex flex-col'>
                          <label className='text-sm font-bold' htmlFor='confirmPassword'>
                            Confirm Password
                          </label>

                          <FormPasswordField
                            autoComplete={'current-password'}
                            name={'confirmPassword'}
                            placeholder={'Confirm your password'}
                          />

                          <ErrorMessage
                            className='mt-2 text-xs font-bold text-primary'
                            component='div'
                            name='confirmPassword'
                          />
                        </div>

                        <div className='text-xs font-medium text-red-500'>{errorMsg}</div>

                        <button
                          className='flex w-full justify-center rounded-full border-0 bg-primary py-2 text-white shadow-sm sm:text-sm sm:leading-6'
                          disabled={isSubmitting}
                          type='submit'
                        >
                          {isSubmitting ? <ButtonLoader /> : <>RESET MY PASSWORD</>}
                        </button>
                      </Form>
                    )
                  }}
                </Formik>

                <div className='mt-10 space-x-1 text-center text-sm'>
                  <span>Your link has expired ?</span>

                  <a className='text-primary underline' href='/forgot-password'>
                    Request another link
                  </a>
                </div>
              </div>
            )
            : (
              <div className='mt-6 flex flex-col items-center justify-center space-y-6'>
                <p className='text-center'>You can now login with your new password.</p>

                <a
                  className='flex w-full justify-center rounded-full border-0 bg-primary py-2 text-white shadow-sm sm:text-sm sm:leading-6'
                  href='/login'
                >
                  BACK TO LOG IN
                </a>
              </div>
            )}
        </div>
      </div>

      <div className='relative hidden w-0 flex-1 lg:block'>
        <img
          alt=''
          className='absolute inset-0 size-full object-cover'
          src='/assets/bg-login.jpg'
        />
      </div>
    </div>
  )
}

export default ResetPassword
