import { PlusIcon } from '@heroicons/react/24/solid'
import classNames from 'classnames'

interface ButtonPlusProps {
  enabled?: boolean
  href: string
  text: string
  theme?: string
}

enum ButtonPlusTheme {
  dark = 'dark',
  light = 'light',
}

const ButtonPlus = ({
  enabled = true,
  href,
  text,
  theme = ButtonPlusTheme.dark
}: ButtonPlusProps) => {
  return (
    <a
      className={classNames(
        'inline-flex items-center space-x-2 rounded-full border py-1 pl-1 pr-5 text-sm font-semibold',
        {
          'bg-black text-white': theme === ButtonPlusTheme.dark && enabled,
          'border-gray-100 bg-gray-30 text-black': theme === ButtonPlusTheme.light,
          'border-gray-200': theme === ButtonPlusTheme.dark,
          'cursor-not-allowed bg-gray-300': theme === ButtonPlusTheme.dark && !enabled
        }
      )}
      href={href}
    >
      <span className='rounded-full bg-primary p-1'>
        <PlusIcon
          className={classNames('size-7', {
            'text-white': theme === ButtonPlusTheme.light
          })}
        />
      </span>

      <span>{text}</span>
    </a>
  )
}

export default ButtonPlus
