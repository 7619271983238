import { CheckIcon, XMarkIcon } from '@heroicons/react/24/outline'
import moment from 'moment/moment'

import ButtonTooltip from '@components/button-tooltip/button-tooltip'
import { type Code } from '@interfaces/api/code'
import { type CodeBulk } from '@interfaces/api/code-bulk'
import usePatchCodeBulk from '@services/api/use-patch-code-bulk'
import { captureException } from '@services/exceptions/capture-exception'

interface CodeListEntryProps {
  code: Code
  codeBulk: CodeBulk
  key: string
  updateLocalCode: (updatedCode: Code) => void
}
const CodeListEntry = ({ code, codeBulk, key, updateLocalCode }: CodeListEntryProps) => {
  const { mutateAsync: patchCodeBulk } = usePatchCodeBulk()
  const onCopyClick = async (code: string) => {
    await navigator.clipboard.writeText(code)
  }

  const setCodeSent = async () => {
    try {
      await patchCodeBulk({
        data: {
          codes: [code.code],
          sentAt: new Date().toISOString()
        },
        uid: codeBulk.uid
      })
      updateLocalCode({ ...code, sentAt: new Date() })
    } catch (error) {
      captureException(error as Error)
    }
  }

  const setCodeUnsent = async () => {
    try {
      await patchCodeBulk({
        data: {
          codes: [code.code],
          sentAt: null
        },
        uid: codeBulk.uid
      })
      updateLocalCode({ ...code, sentAt: '' })
    } catch (error) {
      captureException(error as Error)
    }
  }

  return (
    <tr className='group cursor-pointer bg-white text-sm hover:bg-gray-30' key={key}>
      <td className='px-3 py-2 font-medium'>{code.code}</td>

      <td className='px-3 py-2'>{code.usedAt ? moment(code.usedAt).format('DD.MM.YYYY HH:mm') : '-'}</td>

      <td className='px-3 py-2'>
        {code.sentAt ? moment(code.sentAt).format('DD.MM.YYYY HH:mm') : '-'}
      </td>

      <td className='px-3 py-2'>
        <div className='flex items-center space-x-1'>
          {code.sentAt
            ? (
              <button
                className='rounded-md border border-gray-200 p-1 text-black hover:bg-black hover:text-white'
                onClick={setCodeUnsent}
                type='button'
              >
                <XMarkIcon className='size-4' />
              </button>
            )
            : (
              <button
                className='rounded-md border border-gray-200 p-1 text-black hover:bg-black hover:text-white'
                onClick={setCodeSent}
                type='button'
              >
                <CheckIcon className='size-4' />
              </button>
            )}

          <ButtonTooltip handleCopyClick={async () => {
            await onCopyClick(code.code).catch(captureException)
          }} withBorder
          />
        </div>
      </td>
    </tr>
  )
}

export default CodeListEntry
