import { useQuery, type UseQueryResult } from '@tanstack/react-query'

import { type ApiError } from '@interfaces/api'
import { type Order } from '@interfaces/api/order'
import useAxios from '@services/api/axios'

const useGetOrder = (orderUid: string): UseQueryResult<Order, ApiError> => {
  const { get } = useAxios()

  return useQuery<Order, ApiError>({
    enabled: !!orderUid,
    queryFn: async () =>
      await get<undefined, Order>(`api/partner-realm/partner-orders/${orderUid}`),
    queryKey: ['order', orderUid],
    staleTime: Infinity
  })
}

export default useGetOrder
