import { CheckIcon, EyeIcon, MinusIcon } from '@heroicons/react/24/outline'
import { PlusIcon } from '@heroicons/react/24/solid'
import classNames from 'classnames'
import React, { useEffect, useState } from 'react'

import { type ExperienceCardProps } from '@components/experience-card/experience-card.interfaces'
import { type Experience } from '@interfaces/api/experience'

const ExperienceCard = ({ drawer = false, experience, onAddClick, onDetailsClick, quantityInCart, updateDrawerQuantity }: ExperienceCardProps) => {
  const [quantity, setQuantity] = useState(quantityInCart)

  useEffect(() => {
    setQuantity(quantityInCart)
  }, [quantityInCart])

  const handleAddToCart = () => {
    onAddClick(experience, quantityInCart > 0 ? 0 : 1)
  }

  const handleDelayedAddTocart = (experience: Experience, quantity: number) => {
    onAddClick(experience, quantity)
  }

  const handleDetailsClick = () => {
    onDetailsClick(experience)
  }

  const handleDecrease = () => {
    if (quantity > 0) {
      setQuantity(quantity - 1)

      if (!drawer) {
        handleDelayedAddTocart(experience, quantity - 1)
      } else {
        updateDrawerQuantity && updateDrawerQuantity(quantity - 1)
      }
    }
  }

  const handleIncrease = () => {
    setQuantity(quantity + 1)
    if (!drawer) {
      handleDelayedAddTocart(experience, quantity + 1)
    } else {
      updateDrawerQuantity && updateDrawerQuantity(quantity + 1)
    }
  }

  const handleInputChange = (e) => {
    const newQuantity = parseInt(e.target.value)
    if (!isNaN(newQuantity) && newQuantity >= 0) {
      setQuantity(newQuantity)

      if (!drawer) {
        handleDelayedAddTocart(experience, newQuantity)
      } else {
        updateDrawerQuantity && updateDrawerQuantity(newQuantity)
      }
    } else {
      setQuantity(1)
      if (!drawer) {
        handleDelayedAddTocart(experience, 1)
      } else {
        updateDrawerQuantity && updateDrawerQuantity(1)
      }
    }
  }

  return (
    <div className={classNames(
      '',
      {
        'flex items-center flex-col': !drawer,
        'grid grid-cols-2 gap-4': drawer
      }
    )}
    >
      <button
        className={classNames(
          'group relative hover:cursor-pointer'
        )}
        onClick={quantityInCart > 0 ? handleAddToCart : handleDetailsClick}
        type='button'
      >
        {experience.cover
          ? (
            <img
              alt={experience.name}
              className='size-48 rounded-full object-cover transition-opacity duration-200 ease-in-out group-hover:opacity-70'
              src={experience.cover.url}
            />
          )
          : (
            <div className='size-48 rounded-full bg-gray-200 transition-opacity duration-200 ease-in-out hover:opacity-70' />
          )}

        {quantityInCart <= 0 && (
          <div className='absolute inset-0 m-auto flex size-9 items-center justify-center rounded-full border-[1.5px] border-white bg-black/50 opacity-0 transition-all duration-200 ease-in-out group-hover:opacity-100'>
            <EyeIcon className='size-6 text-white' />
          </div>
        )}

        {quantityInCart > 0 && !drawer && (
          <>
            <div className='absolute inset-0 size-full rounded-full bg-black/60' />
            <div className='absolute inset-0 m-auto flex size-9 items-center justify-center rounded-full bg-primary opacity-100'>
              <CheckIcon className='size-5 text-white' />
            </div>
          </>
        )}
      </button>

      <div className={classNames(
        'flex w-full flex-col',
        {
          'px-2 justify-center': drawer
        }
      )}
      >
        <div className={classNames(
          'mt-4 w-full',
          {
            'text-center mb-8': !drawer,
            'text-left': drawer
          }
        )}
        >
          <h3 className='text-wrap text-base'>{experience.name}</h3>

          <p className='text-base text-primary'>{experience.city.name}</p>
        </div>

        <div className={classNames(
          'flex w-full flex-col',
          {
            'items-center space-y-2': !drawer,
            'items-start': drawer
          }
        )}
        >
          <p className={classNames(
            'space-x-1',
            {
              'mt-4 mb-2': drawer
            }
          )}
          >
            <span className='font-semibold'>{`${(experience.partnerPrice / 100).toFixed(2)}€`}</span>

            <span className='text-black/50'>/ Unit</span>
          </p>

          {!drawer && (
            <button
              className={classNames(
                'mt-2 flex w-full items-center justify-center space-x-2 rounded px-4 py-2 text-sm font-bold uppercase text-white transition-all duration-200 ease-in-out hover:bg-black',
                {
                  'bg-black': quantityInCart > 0,
                  'bg-primary': quantityInCart <= 0
                }
              )}
              onClick={drawer ? handleAddToCart : handleDetailsClick}
              type='button'
            >
              {quantityInCart > 0 && <CheckIcon className='size-4' />}

              <span>{quantityInCart > 0 ? 'Selected' : 'Add to Cart'}</span>
            </button>
          )}

          {(quantityInCart > 0 || drawer) && (
            <div className='flex max-w-full space-x-2'>
              <button
                className='flex h-full items-center justify-center rounded-md bg-black/5 px-1.5'
                onClick={handleDecrease}
              >
                <MinusIcon className='size-3' />
              </button>

              <input
                className='w-full rounded-md border border-gray-300 py-1 text-center text-sm'
                onChange={handleInputChange}
                type='text'
                value={quantity}
              />

              <button
                className='flex h-full items-center justify-center rounded-md bg-black/5 px-1.5'
                onClick={handleIncrease}
              >
                <PlusIcon className='size-3' />
              </button>
            </div>
          )}

          {drawer && (
            <div className='mt-5 flex w-full justify-between'>
              <span>TOTAL</span>

              <span className='font-bold text-primary'>{`${((quantity * experience.partnerPrice) / 100).toFixed(2)}€`}</span>
            </div>
          )}

          {!drawer && (
            <button className='text-sm text-black/70' onClick={handleDetailsClick} type='button'>
              More details
            </button>
          )}
        </div>
      </div>
    </div>
  )
}

export default ExperienceCard
