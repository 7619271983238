import { type Nullable } from '@interfaces'
import { ErrorMessage, Field, Form, Formik, type FormikHelpers } from 'formik'
import { useState } from 'react'
import * as Yup from 'yup'

import ButtonLoader from '@components/button-loader/button-loader'
import FormImageField from '@components/form-image-field'
import FormTextField from '@components/form-text-field/form-text-field'
import LangSwitcher from '@components/lang-switcher/lang-switcher'
import { type Image } from '@interfaces/api/image'
import { type TranslationData } from '@interfaces/api/translation-data'
import { LanguageCodes } from '@services/languages'

const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/png']
const FILE_SIZE = 2 * 1024 * 1024 // 2 MB

const FormSchema = (initialImage: Nullable<Image>) =>
  Yup.object({
    ctaLink: Yup.string().url('CTA Link must be a valid URL').notRequired(),
    ctaTitle: Yup.string().notRequired(),
    description: Yup.string().notRequired(),
    image: Yup.mixed().required('Image is required').test('image-validation', 'Image is required', function (value) {
      if (value && 'uid' in value && initialImage?.uid === value.uid) {
        return true
      }
      if (!value) {
        return false
      }

      const file = value as File
      if (file.size > FILE_SIZE) {
        return this.createError({ message: 'File too large' })
      }

      if (!SUPPORTED_FORMATS.includes(file.type)) {
        return this.createError({ message: 'Unsupported File Format' })
      }

      return true
    }),
    title: Yup.string().required('Title is required')
  })

export interface SlideshowItemFormValues {
  ctaLink: string
  ctaTitle: string
  description: string
  image: Nullable<Image>
  priority: Nullable<number>
  title: string
  translatedData: TranslationData
}

interface SlideshowItemFormProps {
  initialValues: SlideshowItemFormValues
  onSubmit: (values: SlideshowItemFormValues, helpers: FormikHelpers<SlideshowItemFormValues>) => void
}

const SlideshowItemForm = ({ initialValues, onSubmit }: SlideshowItemFormProps) => {
  const [
    translatedDataLanguage,
    setTranslatedDataLanguage
  ] = useState<LanguageCodes>(LanguageCodes.en)

  const translatedDataFieldAttributes = [
    { fieldName: 'translatedData', label: 'Title', name: 'title' },
    { fieldName: 'translatedData', label: 'Description', name: 'description' },
    { fieldName: 'translatedData', label: 'Button text', name: 'ctaTitle' }
  ]

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={onSubmit}
      validateOnBlur={false}
      validationSchema={FormSchema(initialValues.image)}
    >
      {({ isSubmitting, setFieldValue, values }) => (
        <Form className='space-y-10'>

          <FormTextField label={'Title'} name={'title'} placeholder={'Discover the Louvre'} required />

          <div className='flex flex-col'>
            <label className='text-sm font-bold' htmlFor='description'>
              Description
            </label>

            <Field
              className='mt-2 block w-full rounded-lg border px-3 py-2.5 text-sm'
              name='description'
              placeholder=''
            />

            <ErrorMessage className='mt-2 text-xs font-bold text-primary' component='div' name='description' />
          </div>

          <div className='flex flex-col'>
            <label className='text-sm font-bold' htmlFor='ctaTitle'>
              Button text
            </label>

            <Field
              className='mt-2 block w-full rounded-lg border px-3 py-2.5 text-sm'
              name='ctaTitle'
              placeholder='See event'
            />

            <ErrorMessage className='mt-2 text-xs font-bold text-primary' component='div' name='ctaTitle' />
          </div>

          <div className='flex flex-col'>
            <label className='text-sm font-bold' htmlFor='ctaLink'>
              Button link
            </label>

            <Field
              className='mt-2 block w-full rounded-lg border px-3 py-2.5 text-sm'
              name='ctaLink'
              placeholder='https://www.exemple.tld/'
            />

            <ErrorMessage className='mt-2 text-xs font-bold text-primary' component='div' name='ctaLink' />
          </div>

          <div className='mb-10 flex flex-col space-y-4'>
            <FormImageField
              horizontal
              label='Image'
              name={'image'}
              setFieldValue={setFieldValue}
              value={values.image}
            />

            <div className='text-xs'>Jpg or png (max size 2mo)</div>
          </div>

          <div className='mt-4 flex flex-col gap-3 border-t border-gray-900/10 pt-4'>
            <h2 className='text-base font-semibold leading-7 text-gray-900'>Translations</h2>

            <LangSwitcher
              setTranslatedDataLanguage={setTranslatedDataLanguage}
              translatedDataLanguage={translatedDataLanguage}
            />

            <div className='flex w-full flex-col gap-3'>
              {translatedDataFieldAttributes.map((field) => {
                return (
                  <FormTextField
                    key={`${field.fieldName}.${translatedDataLanguage}.${field.name}`}
                    label={field.label}
                    name={`${field.fieldName}.${translatedDataLanguage}.${field.name}`}
                  />
                )
              })}
            </div>
          </div>

          <div className='flex space-x-4'>
            <button
              className='flex w-full justify-center rounded-full border-0 bg-primary py-2 text-white shadow-sm sm:text-sm sm:leading-6'
              disabled={isSubmitting}
              type='submit'
            >
              {isSubmitting ? <ButtonLoader /> : <>SAVE</>}
            </button>
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default SlideshowItemForm
