import React from 'react'
import { Link } from 'react-router-dom'

import { type CodeBulk } from '@interfaces/api/code-bulk'

interface CodeBulksListEntryInterfaces {
  codeBulk: CodeBulk
}
const CodeBulksListEntry = ({ codeBulk }: CodeBulksListEntryInterfaces) => {
  return (
    <Link className='group flex cursor-pointer items-center justify-between rounded-full border border-gray-50 bg-white py-1 text-sm hover:bg-gray-30' key={codeBulk.uid} to={`/codes/${codeBulk.uid}`}>

      <div className='flex w-1/2 items-center space-x-5'>
        <div className='px-3 py-2'>
          {codeBulk.experience.cover
            ? (
              <img
                alt={codeBulk.experience.name}
                className='size-14 rounded-full object-cover transition-opacity duration-200 ease-in-out group-hover:opacity-70'
                src={codeBulk.experience.cover.url}
              />
            )
            : (
              <div
                className='size-14 rounded-full bg-gray-200 transition-opacity duration-200 ease-in-out hover:opacity-70'
              />
            )}
        </div>

        <div className='px-3 py-2'>
          <div className='flex flex-col'>
            <span>{codeBulk.experience.name}</span>

            <span className='text-primary'>{codeBulk.experience?.city?.name}</span>
          </div>
        </div>
      </div>

      <div className='flex w-1/2 items-center justify-between pr-4'>
        <div className='space-x-1 px-3 py-2'>
          <span>Used</span>

          <span className='font-bold'>{codeBulk.totalCount - codeBulk.remainingCount}</span>
        </div>

        <div className='space-x-1 px-3 py-2'>
          <span>Stock</span>

          <span className='font-bold text-primary'>
            x
            {codeBulk.totalCount}
          </span>
        </div>

        <div className='px-3 py-2' />
      </div>
    </Link>
  )
}

export default CodeBulksListEntry
