/* eslint-disable jsx-a11y/label-has-associated-control */
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import classNames from 'classnames'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'

import ButtonLoader from '@components/button-loader/button-loader'
import Guard from '@components/guard/guard'
import Layout from '@components/layout/layout'
import type { Experience } from '@interfaces/api/experience'
import type { Payment } from '@interfaces/api/payment'
import CheckoutForm from '@pages/purchase/checkout/checkout-form/checkout-form'
import OrderItem from '@pages/purchase/checkout/order-item/order-item'
import useCancelPayment from '@services/api/use-cancel-payment'
import useCreatePayment from '@services/api/use-create-payment'
import useGetOrder from '@services/api/use-get-order'
import usePatchOrder from '@services/api/use-patch-order'
import { captureException } from '@services/exceptions/capture-exception'
import { useCartStore } from '@services/stores/cart/cart'

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK ?? '')

const Checkout = () => {
  const { addToCart, clearCart, getCartUid, getPaymentUid, getTotal, items, setPaymentUid } = useCartStore()

  const [clientSecret, setClientSecret] = useState('')
  const [paymentLoading, setPaymentLoading] = useState(false)
  const [timeoutId, setTimeoutId] = useState<unknown>(null)
  const [checked, setChecked] = useState(false)
  const navigate = useNavigate()
  const cartUid = getCartUid()
  const { data: order } = useGetOrder(cartUid)

  const { mutateAsync: createPayment } = useCreatePayment()
  const { mutateAsync: patchOrder } = usePatchOrder()
  const { mutateAsync: cancelPayment } = useCancelPayment()

  useEffect(() => {
    const paymentUid = getPaymentUid()

    if (
      paymentUid &&
      order &&
      order?.paymentState !== 'cart' &&
      order?.paymentState !== 'cancelled' &&
      order.paymentState !== 'paid'
    ) {
      cancelPayment(paymentUid).catch(captureException)
    } else if (order?.checkoutState === 'completed' && order.paymentState === 'paid') {
      clearCart()
    }
  }, [order])

  const onBuyClick = () => {
    const cartUid = getCartUid()
    setPaymentLoading(true)

    createPayment({
      partnerOrder: `/api/partner-realm/partner-orders/${cartUid}`
    })
      .then((data: Payment) => {
        setPaymentLoading(false)
        setClientSecret(data.stripeData.paymentIntentClientSecret)
        setPaymentUid(data.uid)
      })
      .catch(captureException)
  }

  const handleAddToCart = async (experience: Experience, quantity: number) => {
    if (!cartUid) {
      navigate('/purchase')
    } else {
      if (timeoutId) {
        clearTimeout(timeoutId as string)
      }

      setTimeoutId(
        setTimeout(() => {
          patchOrder({
            data: {
              append: false,
              experience: experience['@id'],
              quantity
            },
            uid: cartUid
          }).catch(captureException)
        }, 1000)
      )
    }

    addToCart({ experience: experience.uid, quantity, unitPrice: experience.partnerPrice })
  }

  return (
    <Layout wide>
      <Guard errorPage={false}>
        <>
          <div>
            {!clientSecret
              ? (
                <>
                  <div className='mx-auto flex max-w-7xl flex-col items-center px-2 pb-28 sm:px-6 lg:px-8'>
                    <div className='mt-10 text-center'>
                      <h2 className='text-2xl font-bold'>Buy codes</h2>

                      <p className='my-2'>Select the Attraction and Buy It</p>
                    </div>

                    <div className='my-10 flex max-w-5xl flex-col gap-6'>
                      <div className='flex flex-col gap-6'>
                        {order?.items.map((orderItem) => {
                          const quantityInCart =
                          items.find((item) => item.experience === orderItem.experience.uid)
                            ?.quantity || 0

                          if (quantityInCart <= 0) {
                            return <></>
                          }

                          return (
                            <OrderItem
                              experience={orderItem.experience}
                              onAddClick={handleAddToCart}
                              quantityInCart={quantityInCart}
                              unitPrice={orderItem.unitPrice}
                            />
                          )
                        })}
                      </div>

                      <div className='grid w-full grid-cols-2 items-center gap-4'>
                        <div className='flex items-start'>
                          <input
                            checked={checked}
                            className='opacity-0'
                            id='agreeCheckbox'
                            type='radio'
                          />

                          <label
                            className='mr-4 flex items-center justify-center rounded-full border border-black p-1.5'
                            htmlFor='agreeCheckbox'
                            onClick={() => {
                              setChecked(!checked)
                            }}
                            onKeyDown={(event) => {
                              if (event.key === 'Enter' || event.key === ' ') {
                                setChecked(!checked)
                              }
                            }}
                          >
                            <div
                              className={classNames('h-[10px] w-[10px] rounded-full', {
                                'bg-black/10': !checked,
                                'bg-primary': checked
                              })}
                            />
                          </label>

                          <button
                            className='space-x-1 text-left'
                            onClick={() => {
                              setChecked(!checked)
                            }}
                          >
                            <span>
                              I agree to receive offers, news and feature notifications from
                            </span>

                            <span className='font-semibold text-primary underline'>CityCrush</span>

                            <span>, including by email</span>
                          </button>
                        </div>

                        <div className='flex w-full items-center justify-between rounded-full border border-black/10 px-6 py-4'>
                          <span className='text-base'>TOTAL </span>

                          <span className='text-lg font-semibold text-primary'>
                            {`${(getTotal() / 100).toFixed(2)} €`}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='fixed bottom-0 flex h-20 w-full items-center justify-center space-x-10 border border-b-0 border-t-black/10 bg-white/50 py-4 backdrop-blur'>
                    <a
                      className='flex justify-center rounded-full border-0 bg-black px-20 py-2 uppercase text-white'
                      href='/purchase'
                    >
                      Continue booking
                    </a>

                    <button
                      className='flex justify-center rounded-full border-0 bg-primary px-20 py-2 uppercase text-white'
                      onClick={onBuyClick}
                      type='submit'
                    >
                      {!paymentLoading ? <span>BUY NOW</span> : <ButtonLoader />}
                    </button>
                  </div>
                </>
              )
              : (
                <>
                  <div className='mx-auto flex max-w-7xl flex-col items-center px-2 pb-20 sm:px-6 lg:px-8'>
                    <div className='mt-10 text-center'>
                      <h2 className='text-2xl font-bold'>Buy codes</h2>

                      <p className='my-2'>Payment processing</p>
                    </div>

                    <div className='mt-20 w-full max-w-2xl'>
                      <Elements
                        options={{
                          clientSecret
                        }}
                        stripe={stripePromise}
                      >
                        <CheckoutForm />
                      </Elements>
                    </div>
                  </div>
                </>
              )}
          </div>
        </>
      </Guard>
    </Layout>
  )
}

export default Checkout
