import classNames from 'classnames'
import React, { useEffect, useState } from 'react'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'

import ModalDeleteConfirm from '@components/modal-delete-confirm/modal-delete-confirm'
import { type SlideshowItem } from '@interfaces/api/slideshow-item'
import SlideshowItemModal from '@pages/settings/settings-slideshow/slideshow-item-modal/slideshow-item-modal'
import SlideshowItemRow from '@pages/settings/settings-slideshow/slideshow-items/slideshow-item'
import useDeleteSlideshowItem from '@services/api/use-delete-slideshow-item'
import useGetSlideshowEvents from '@services/api/use-get-slideshow-items'
import usePatchSlideshowItem from '@services/api/use-patch-slideshow-item'
import { captureException } from '@services/exceptions/capture-exception'

const SlideshowItems = () => {
  const [openModal, setOpenModal] = useState(false)
  const [openModalDelete, setOpenModalDelete] = useState(false)
  const [currentSlideshowItem, setCurrentSlideshowItem] = useState('')
  const [slideshowItemToDelete, setSlideshowItemToDelete] = useState('')
  const { data: slideshowItems, refetch } = useGetSlideshowEvents<SlideshowItem>()
  const { mutateAsync: deleteItem } = useDeleteSlideshowItem()
  const { mutateAsync: patchSlideshowItem } = usePatchSlideshowItem()

  const [items, setItems] = useState<SlideshowItem[]>([])

  useEffect(() => {
    if (slideshowItems?.data && (!items.length || items.length !== slideshowItems.data.length)) {
      setItems(slideshowItems.data)
    }
  }, [slideshowItems])

  const handleAddItem = () => {
    setOpenModal(true)
  }

  const openDeleteModal = (uid: string) => {
    setOpenModalDelete(true)
    setSlideshowItemToDelete(uid)
  }

  const onDeleteConfirm = () => {
    deleteItem({ uid: slideshowItemToDelete }).then(() => {
      setSlideshowItemToDelete('')
      setOpenModalDelete(false)
      refetch().catch(captureException)
    }).catch(captureException)
  }

  const openEditModal = (uid: string) => {
    setCurrentSlideshowItem(uid)
  }

  useEffect(() => {
    if (currentSlideshowItem && !openModal) {
      setOpenModal(true)
    }
  }, [currentSlideshowItem])

  useEffect(() => {
    if (!openModal) {
      setCurrentSlideshowItem('')
    }
  }, [openModal])

  const renderSlotsAvailable = () => {
    const slotsAvailable = 5 - (slideshowItems?.data.length ?? 0)

    return (
      <span className='text-xs'>{`${slotsAvailable} ${slotsAvailable > 1 ? 'slots' : 'slot'} available`}</span>
    )
  }

  const onDragEnd = (result) => {
    if (!result.destination) {
      return
    }

    const sourceIndex = result.source.index
    const destinationIndex = result.destination.index
    const newPriority = items[result.destination.index].priority

    if (sourceIndex === destinationIndex) {
      return
    }

    const newItems = Array.from(items)
    const [removed] = newItems.splice(sourceIndex, 1)

    newItems.splice(destinationIndex, 0, removed)
    setItems(newItems)

    patchSlideshowItem({
      data: {
        priority: newPriority
      },
      uid: result.draggableId
    }).catch(captureException)
  }

  const handleModalSuccess = (updatedItem: SlideshowItem) => {
    refetch().catch(captureException)
    if (updatedItem) {
      setItems(prevItems => {
        const index = prevItems.findIndex(item => item.uid === updatedItem.uid)
        if (index !== -1) {
          const newItems = [...prevItems]
          newItems[index] = updatedItem

          return newItems
        }

        return [...prevItems, updatedItem]
      })
    }
  }

  return (
    <div>
      {slideshowItems && renderSlotsAvailable()}

      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable direction='vertical' droppableId='droppable'>
          {(provided) => (
            <div
              {...provided.droppableProps}
              className='flow-root'
              ref={provided.innerRef}
            >
              <div className='my-4 space-y-4'>
                {items?.map((item: SlideshowItem, index) => (
                  <Draggable draggableId={item.uid} index={index} key={item.uid}>
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        className={'my-4'}
                        key={item.uid}
                      >
                        <SlideshowItemRow
                          item={item}
                          openDeleteModal={openDeleteModal}
                          openEditModal={openEditModal}
                        />
                      </div>
                    )}
                  </Draggable>
                ))}

                {provided.placeholder}
              </div>
            </div>
          )}
        </Droppable>
      </DragDropContext>

      <button
        className={classNames(
          'flex w-full uppercase justify-center rounded-full border-0 bg-primary py-2 text-white shadow-sm sm:text-sm sm:leading-6',
          {
            'cursor-not-allowed bg-primary/50': slideshowItems && slideshowItems.data.length >= 5
          }
        )}
        disabled={slideshowItems && slideshowItems.data.length >= 5}
        onClick={handleAddItem}
      >
        Add item
      </button>

      <ModalDeleteConfirm onConfirm={onDeleteConfirm} open={openModalDelete} setOpen={setOpenModalDelete}
        text={'Are your sure to delete this content ?'} title={'Confirm deletion'}
      />

      <SlideshowItemModal onSuccess={handleModalSuccess} open={openModal} setOpen={setOpenModal} uid={currentSlideshowItem} />
    </div>
  )
}

export default SlideshowItems
