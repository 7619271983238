import { useMutation, type UseQueryOptions } from '@tanstack/react-query'

import useAxios from '@services/api/axios'

export interface BookingPatch {
  allDay: boolean
  customerEmail: string
  customerFirstName: string
  customerLastName: string
  customerPhone: string
  lang: boolean
  reference: string
  startTimeLocal: string
  withTickets: boolean
}
interface UpdateBookingParameters extends Omit<UseQueryOptions, 'queryKey'> {
  data: BookingPatch
  uid: string
}
const usePatchBooking = () => {
  const { patch } = useAxios()

  return useMutation({
    mutationFn: async ({ data, uid }: UpdateBookingParameters) => {
      return await patch<never, never>(
        `api/partner-realm/partner-bookings/${uid}`,
        data as never
      )
    }
  })
}

export default usePatchBooking
