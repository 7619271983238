import { type ApiError } from '@interfaces/api'

export const handleImageViolations = (violations: ApiError[]) => {
  let error = 'An unknown error occurred during the upload'
  if (!violations?.length) {
    return error
  }
  for (const violation of violations) {
    if (violation.message.includes('The image height is too big')) {
      error = 'The maximum allowed height is 4096px'
    }
    if (violation.message.includes('The image width is too big')) {
      error = 'The maximum allowed width is 4096px'
    }
    if (violation.message.includes('The file is too large.')) {
      error = 'The file is too large. The maximum allowed size is 2 MB'
    }
  }

  return error
}
