import { useMutation, type UseMutationResult } from '@tanstack/react-query'

import { type User } from '@interfaces/api/user'
import useAxios from '@services/api/axios'

export const usePatchMe = (): UseMutationResult<User, unknown, Partial<User>> => {
  const { patch } = useAxios()

  return useMutation<User, unknown, Partial<User>, unknown>({
    mutationFn: async (data) => await patch<never, never>('/api/partner-realm/me', data as never)
  })
}

export default usePatchMe
