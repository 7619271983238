import { type ApiPlatformEntity } from '@interfaces/api/api'
import { type Experience } from '@interfaces/api/experience'

export enum BookingState {
  cancelled = 'cancelled',
  completed = 'completed',
  pending = 'pending',
  ready = 'ready'
}

export interface Booking extends ApiPlatformEntity {
  allDay: boolean
  createdAt: string
  customerEmail: string
  customerFirstName: string
  customerLastName: string
  customerPhone: string
  experience: Experience
  files?: BookingFile[]
  lang: string
  quantity: number
  reference: string
  startTime: string
  startTimeLocal: string
  state: BookingState
  timezone: string
  uid: string
  updatedAt: string
  withTickets: boolean
}

export interface BookingFile extends ApiPlatformEntity {
  mime: string
  originalName: string
  size: number
  url: string
}
