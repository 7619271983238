import { useMutation, type UseMutationResult } from '@tanstack/react-query'

import {
  type ApiRequestRegisterPass,
  type ApiResponseRegisterPass,
  isApiResponseRegisterPassSuccess
} from '@interfaces/api/register/pass'
import useAxios from '@services/api/axios'
import { useAuthToken, useRefreshToken } from '@services/hooks/auth-token'

export const useSignUpMutation = (): UseMutationResult<
ApiResponseRegisterPass,
unknown,
ApiRequestRegisterPass
> => {
  const { post } = useAxios()
  const { set: setAuthToken } = useAuthToken()
  const { set: setRefreshToken } = useRefreshToken()

  return useMutation<ApiResponseRegisterPass, unknown, ApiRequestRegisterPass>({
    mutationFn: async (data) =>
      await post<ApiRequestRegisterPass, ApiResponseRegisterPass>(
        '/api/partner-realm/account/register',
        data
      ),
    onSuccess: (response) => {
      if (isApiResponseRegisterPassSuccess(response)) {
        setAuthToken(response.data.token)
        setRefreshToken(response.data.refresh_token)
      }
    }
  })
}
