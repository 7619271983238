import { useMutation, type UseQueryOptions } from '@tanstack/react-query'

import { type SlideshowItemFormValues } from '@pages/settings/settings-slideshow/slideshow-item-form/slideshow-item-form'
import useAxios from '@services/api/axios'

interface UpdateSlideshowItemParameters extends Omit<UseQueryOptions, 'queryKey'> {
  data: Partial<SlideshowItemFormValues>
  uid: string
}
const usePatchSlideshowItem = () => {
  const { patch } = useAxios()

  return useMutation({
    mutationFn: async ({ data, uid }: UpdateSlideshowItemParameters) => {
      return await patch<never, never>(
        `api/partner-realm/slideshow-items/${uid}`,
        data as never
      )
    }
  })
}

export default usePatchSlideshowItem
