import classNames from 'classnames'
import React from 'react'
import { Link } from 'react-router-dom'

import { type ButtonProps } from '@components/button/button.interfaces'
import ButtonLoader from '@components/button-loader/button-loader'

const Button = ({
  children,
  className,
  disabled = false,
  href,
  icon: Icon,
  iconRight = true,
  isActive = false,
  isLoading = false,
  onClick,
  size = 'large',
  style = 'basic',
  type = 'button'
}: ButtonProps) => {
  const buttonStyle = classNames(
    'flex gap-2 items-center justify-center rounded-full py-2 uppercase',
    className,
    {
      'bg-black text-white': style === 'gray' && isActive,
      'bg-black text-white hover:bg-primary': !disabled && !isLoading && style === 'black',
      'bg-gray-50 text-black': style === 'gray' && !isActive,
      'bg-primary text-white hover:bg-black': !disabled && !isLoading && style === 'basic',
      'border border-green-500 bg-green-500 text-white fill-green-500 hover:bg-white hover:text-green-500 hover:fill-white': style === 'success',
      'border border-red-500 bg-white text-red-500 fill-red-500 hover:border-red-500 hover:bg-red-500 hover:text-white hover:fill-white': style === 'warning',
      'cursor-not-allowed bg-slate-400': disabled || isLoading,
      'cursor-pointer': !disabled && !isLoading,
      'px-2': size === 'small',
      'px-4 text-xs': size === 'medium',
      'px-20': size === 'large'
    }
  )

  const iconStyle = classNames(
    'flex-none',
    {
      'size-5': size === 'medium',
      'size-6': size === 'large' || size === 'small'
    }
  )

  const content = (
    <>
      {!isLoading && Icon && !iconRight && <Icon className={iconStyle} />}
      {children && children}
      {isLoading && <ButtonLoader dark />}
      {!isLoading && Icon && iconRight && <Icon className={iconStyle} />}
    </>
  )

  if (href) {
    return (
      <Link className={buttonStyle} to={href}>
        {content}
      </Link>
    )
  }

  return (
    <button className={buttonStyle} disabled={disabled || isLoading} onClick={onClick} type={type}>
      {content}
    </button>
  )
}

export default Button
