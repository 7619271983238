import { useMutation, type UseMutationResult } from '@tanstack/react-query'

import {
  type ApiRequestResetPassword,
  type ApiResponseResetPassword
} from '@interfaces/api/reset-password/reset-password'
import useAxios from '@services/api/axios'

interface ResetPasswordVariables {
  data: ApiRequestResetPassword
  token: string
}
export const useResetPasswordMutation = (): UseMutationResult<
ApiResponseResetPassword,
unknown,
ResetPasswordVariables
> => {
  const { put } = useAxios()

  return useMutation<ApiResponseResetPassword, unknown, ResetPasswordVariables>({
    mutationFn: async ({ data, token }) =>
      await put<ApiRequestResetPassword, ApiResponseResetPassword>(`/api/partner-realm/reset-password/${token}`, data)
  })
}
