import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { useEffect, useState } from 'react'

import ButtonLoader from '@components/button-loader/button-loader'
import { captureException } from '@services/exceptions/capture-exception'

const CheckoutForm = () => {
  const stripe = useStripe()
  const elements = useElements()

  const [message, setMessage] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (!stripe) {
      return
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      'payment_intent_client_secret'
    )

    if (!clientSecret) {
      return
    }

    stripe
      .retrievePaymentIntent(clientSecret)
      .then(({ paymentIntent }) => {
        if (paymentIntent) {
          switch (paymentIntent.status) {
            case 'succeeded':
              setMessage('Payment succeeded!')
              break
            case 'processing':
              setMessage('Your payment is processing.')
              break
            case 'requires_payment_method':
              setMessage('Your payment was not successful, please try again.')
              break
            default:
              setMessage('Something went wrong.')
              break
          }
        } else {
          setMessage('Something went wrong.')
        }
      })
      .catch(captureException)
  }, [stripe])

  const handleSubmit = async (e) => {
    e.preventDefault()

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable booking-form submission until Stripe.js has loaded.
      return
    }

    setIsLoading(true)

    const returnUrl = `${window.location.origin}/purchase/success`

    const { error } = await stripe.confirmPayment({
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: returnUrl
      },
      elements
    })

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error.type === 'card_error' || error.type === 'validation_error') {
      setMessage(error.message as string)
    } else {
      setMessage('An unexpected error occurred.')
    }

    setIsLoading(false)
  }

  return (
    <form
      className='flex flex-col items-center justify-center'
      id='payment-form'
      onSubmit={handleSubmit}
    >
      <PaymentElement
        className='w-full'
        id='payment-element'
        options={{
          layout: 'tabs'
        }}
      />

      <button
        className='mt-10 flex justify-center rounded-full bg-primary px-20 py-2 uppercase text-white'
        disabled={isLoading || !stripe || !elements}
        id='submit'
      >
        <span id='button-text'>{isLoading ? <ButtonLoader /> : 'Pay now'}</span>
      </button>

      {message && <div id='payment-message'>{message}</div>}
    </form>
  )
}

export default CheckoutForm
