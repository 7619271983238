const EmailVerified = () => {
  return (
    <div className='flex min-h-full flex-1'>
      <div className='flex flex-1 flex-col justify-center px-4 py-12 sm:px-6 lg:px-20 xl:px-24'>
        <div className='mx-auto w-full max-w-sm lg:w-96'>
          <div className='flex flex-col items-center'>
            <a href='/'>
              <img alt='logo' className='h-20' src='/assets/citycrush.svg' />
            </a>

            <h2 className='mt-8 text-xl font-bold leading-9 tracking-tight text-gray-900'>
              Email verified
            </h2>

            <p className='my-4 text-center'>
              Your email has been verified !
              <br />

              {' '}
              If your profile is complete our compliance team will get back to you as soon as possible.
            </p>
          </div>

          <a
            className='mt-4 flex w-full justify-center rounded-full border-0 bg-primary py-2 text-white shadow-sm sm:text-sm sm:leading-6'
            href='/'
          >
            CONTINUE
          </a>

        </div>
      </div>

      <div className='relative hidden w-0 flex-1 lg:block'>
        <img
          alt=''
          className='absolute inset-0 size-full object-cover'
          src='/assets/bg-login.jpg'
        />
      </div>
    </div>
  )
}

export default EmailVerified
